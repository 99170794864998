//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';

var devices = [
  {
    model: 'MV2',
    storage: 0,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 103,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: false,
    resolution1: 1080,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV12N',
    storage: 256,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 73,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: false,
    resolution1: 1080,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV12W',
    storage: 256,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 114,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: false,
    resolution1: 1080,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV12WE',
    storage: 128,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 114,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: false,
    resolution1: 1080,
    peopleDetection: true,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV13',
    storage: 256,
    qualityStandard: 30,
    qualityEnhanced: 20,
    qualityUltra: 15,
    horizontalFov: 101,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: true
  }, {
    model: 'MV13M',
    storage: 512,
    qualityStandard: 60,
    qualityEnhanced: 45,
    qualityUltra: 30,
    horizontalFov: 101,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: true
  }, {
    model: 'MV63',
    storage: 256,
    qualityStandard: 30,
    qualityEnhanced: 20,
    qualityUltra: 15,
    horizontalFov: 102,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: false,
    resolution1: 1520,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: true
  }, {
    model: 'MV63M',
    storage: 512,
    qualityStandard: 60,
    qualityEnhanced: 45,
    qualityUltra: 30,
    horizontalFov: 102,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: true
  }, {
    model: 'MV63X',
    storage: 1000,
    qualityStandard: 120,
    qualityEnhanced: 90,
    qualityUltra: 60,
    horizontalFov: 102,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: true
  }, {
    model: 'MV22',
    storage: 256,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: true,
    resolution1: 1080,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV22X',
    storage: 512,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: true,
    resolution1: 1520,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV23M',
    storage: 512,
    qualityStandard: 60,
    qualityEnhanced: 45,
    qualityUltra: 30,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: true,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: true
  }, {
    model: 'MV23X',
    storage: 1000,
    qualityStandard: 120,
    qualityEnhanced: 90,
    qualityUltra: 60,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: false,
    opticZoom: true,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: true
  }, {
    model: 'MV72',
    storage: 256,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: true,
    resolution1: 1080,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: true
  }, {
    model: 'MV72X',
    storage: 512,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: true,
    resolution1: 1520,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: false
  }, {
    model: 'MV73M',
    storage: 512,
    qualityStandard: 60,
    qualityEnhanced: 45,
    qualityUltra: 30,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: true,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: true
  }, {
    model: 'MV73X',
    storage: 1000,
    qualityStandard: 120,
    qualityEnhanced: 90,
    qualityUltra: 60,
    horizontalFov: 112,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: true,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: true
  }, {
    model: 'MV32',
    storage: 256,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 180,
    wireless: 5,
    ir: false,
    outdoor: false,
    opticZoom: false,
    resolution1: 1520,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV33',
    storage: 256,
    qualityStandard: 30,
    qualityEnhanced: 20,
    qualityUltra: 15,
    horizontalFov: 180,
    wireless: 5,
    ir: false,
    outdoor: false,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: true,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV33M',
    storage: 512,
    qualityStandard: 60,
    qualityEnhanced: 45,
    qualityUltra: 30,
    horizontalFov: 180,
    wireless: 5,
    ir: false,
    outdoor: false,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: true,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV93',
    storage: 256,
    qualityStandard: 30,
    qualityEnhanced: 20,
    qualityUltra: 15,
    horizontalFov: 180,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: false,
    resolution1: 1520,
    peopleDetection: true,
    advancedPeopleCounter: true,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV93M',
    storage: 512,
    qualityStandard: 60,
    qualityEnhanced: 45,
    qualityUltra: 30,
    horizontalFov: 180,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: true,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV93X',
    storage: 1000,
    qualityStandard: 120,
    qualityEnhanced: 90,
    qualityUltra: 60,
    horizontalFov: 180,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: false,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: true,
    vehicleDetection: false,
    attributeSearch: false
  }, {
    model: 'MV52',
    storage: 1000,
    qualityStandard: 0,
    qualityEnhanced: 0,
    qualityUltra: 0,
    horizontalFov: 37,
    wireless: 5,
    ir: true,
    outdoor: true,
    opticZoom: true,
    resolution1: 2160,
    peopleDetection: true,
    advancedPeopleCounter: false,
    vehicleDetection: true,
    attributeSearch: false
  }
]

const wallMountArm = {
  model: 'Wall Mount Arm',
  sku: 'MA-MNT-MV-30',
  description: 'The Wall Mount Arm allows you to mount the camera on a wall but have it face downward.'
}

const wallMountArmGen3 = {
  model: 'Wall Mount Arm',
  sku: 'MA-MNT-MV-48',
  description: 'The Wall Mount Arm allows you to mount a third-generation camera on a wall but have it face downward.'
}

const wallMountArm72 = {
  model: 'Wall Mount Arm',
  sku: 'MA-MNT-MV-10',
  description: 'The Wall Mount Arm allows you to mount the camera on a wall but have it face downward.'
}
const lshapewallmountarm = {
  model: 'L-Shape Wall Mount Arm',
  sku: 'MA-MNT-MV-31',
  description: 'The L-Shape Wall Mount Arm provides a much more compact solution for wall mounting.'
}

const lshapewallmountarmGen3 = {
  model: 'L-Shape Wall Mount Arm',
  sku: 'MA-MNT-MV-38',
  description: 'The L-Shape Wall Mount Arm provides third-generation cameras a much more compact solution for wall mounting.'
}

const telescopeMount = {
  model: 'Telescoping Pendant Mount',
  sku: 'MA-MNT-MV-40',
  description: 'To mount the MV on a very high ceiling, we provide the telescoping mount.'
}
const trailMount = {
  model: 'T-Rail Mount Clip',
  sku: 'MA-MNT-CLG-1',
  description: 'The T-Rail Mount Clip is used to mount the MV onto a recessed T-Rail or flush T-Rail ceiling.'
}
const trailMountGen3 = {
  model: 'T-Rail Mount Clip',
  sku: 'MA-MNT-MV-68',
  description: 'The T-Rail Mount Clip is used to mount a third-generation MV onto a recessed T-Rail or flush T-Rail ceiling.'
}
const poleMount = {
  model: 'Pole Mount',
  sku: 'MA-MNT-MV-20',
  description: 'Use the pole mount to attach a camera on a pole.'
}
const poleMount52 = {
  model: 'Pole Mount',
  sku: 'MA-MNT-MV-21',
  description: 'Use the pole mount to attach a bullet camera to a pole.'
}

const telescopeMountGen3 = {
  model: 'Telescoping Pendant Mount',
  sku: 'MA-MNT-MV-78',
  description: 'Use the pole mount to attach a third-generation camera to a pole.'
}

const poleMountGen3 = {
  model: 'Pole Mount',
  sku: 'MA-MNT-MV-28',
  description: 'Use the pole mount to attach a third-generation camera to a pole.'
}

const conduitGen3 = {
  model: 'Conduit Back Box',
  sku: 'MA-MNT-MV-58',
  description: 'Allows for Ethernet to be securely routed to a third-generation camera.'
}

const cornerGen3 = {
  model: 'Corner Mount',
  sku: 'MA-MNT-MV-88',
  description: 'Use the corner mount to attach a third-generation camera to a corner.'
}

const acPower = {
  model: 'AC Power Adapter',
  sku: 'MA-PWR-USB-xx (xx = US, EU, UK or AU)',
  description: 'Includes a power adapter and a 3m USB-C cable.'
}

const ethPower = {
  model: 'Ethernet Adapter',
  sku: 'MA-PWR-ETH',
  description: 'Includes an ethernet dongle and a 1m USB-C cable.'
}

const retentionTime = {
  storage128: {
    res720p: {
      Standard: "21",
      Enhanced: "15",
      High: null
    },
    res1080p: {
      Standard: "11",
      Enhanced: "7",
      High: "4"
    }
  },
  storage256: {
    res720p: {
      Standard: "45",
      Enhanced: "29",
      High: null
    },
    res1080p: {
      Standard: "22",
      Enhanced: "14",
      High: "7"
    },
    res1080x1080: {
      Standard: "22",
      Enhanced: "11",
      High: null
    },
    res2058x2058: {
      Standard: "22",
      Enhanced: "7",
      High: "4"
    }
  },
  storage512: {
    res720p: {
      Standard: "90",
      Enhanced: "61",
      High: null
    },
    res1080p: {
      Standard: "45",
      Enhanced: "30",
      High: "15"
    },
    res4MP: {
      Standard: "15",
      Enhanced: "6",
      High: null
    }
  }
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ STATE VARIABLES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

class MVPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedSwitchModel: null,
      clickedSwitchModelDetails: null,
      switchModel: null,
      outdoor: null,
      storage: null,
      quality: null,
      opticZoom: null,
      ir: null,
      peopleDetection: null,
      advancedPeopleCounter: null,
      vehicleDetection: null,
      attributeSearch: null,

      smartRetentionDays: null,
      devicesMain: null,

      mvSense: null,
      mvSenseLicenseTerm: null,

      mvDisplay: null,
      mvDisplayLicenseTerm: null,

      mvCA: null,
      mvCADuration: null,
      cloudArchiveLicenseTerm: null,

      license: null,
      licenseTerm: null,

      resolution: null,
      resolutionBubbble: null,
      fps: null,

      retention: null,

      clickedAccessory: null,
      clickedAccessorySku: null,
      clickedAccessorySummary: null

    };
    //Needed for keystroke changes for input boxes
    this.handleChange = this.handleChange.bind(this);
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ CAMERAS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderAccessoryInfo(model, sku, summary) {
    return (<div>
      <div onClick={this.state.clickedAccessory != model
        ? () => this.setState({ clickedAccessory: model, clickedAccessorySku: sku, clickedAccessorySummary: summary })
        : () => this.setState({ clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null })} className={this.state.clickedAccessory == model
          ? 'antennaPicsCircleSelected'
          : 'antennaPicsCircle'}>
        <p className='antennaPics'>{model}</p>
      </div>
    </div>)
  }

  renderSwitchInfo(header, switchModels) {
    return (<div>
      <p>
        <h6 className='switchInfoHeader'>{header + ': '}</h6>
        {switchModels}</p>
    </div>)
  }

  renderSwitchModelDetailsClicked() {
    return (<div>
      {
        this.state.clickedSwitchModel != null
          ? <div className='secondaryOptionsContainer secondaryOptionsContainerModel'>
            <h4>{this.state.clickedSwitchModel}</h4>
            <h5 className="commaToNewLine">{this.state.clickedSwitchModelDetails.replace(/~/g, "\n")}</h5>
          </div>
          : ""
      }
    </div>)
  }

  renderLicenseSKU() {
    if (this.state.licenseTerm != null) {
      return <p>{'1 x LIC-MV-' + this.state.licenseTerm + 'YR'}</p>;
    } else {
      return <p>{'1 x LIC-MV-xYR (x = 1, 3, 5, 7, 10 years)'}</p>;
    }
  }

  renderCALicenseSKU() {
    if (this.state.mvCA === true && this.state.mvCADuration != null && this.state.cloudArchiveLicenseTerm != null) {
      return <p>{'1 x LIC-MV-CA' + this.state.mvCADuration + '-' + this.state.cloudArchiveLicenseTerm + 'YR'}</p>;
    } else if (this.state.mvCA === true && this.state.mvCADuration != null) {
      return <p>{'1 x LIC-MV-CA' + this.state.mvCADuration + '-xYR (x = 1, 3, 5, 7, 10 years)'}</p>;
    } else {
      return null;
    }
  }

  renderSenseLicenseSKU() {
    if (this.state.mvSense == true && this.state.mvSenseLicenseTerm != null) {
      return <p>{'1 x LIC-MV-SEN-' + this.state.mvSenseLicenseTerm + 'YR'}</p>;
    } else if (this.state.mvSense == true) {
      return <p>{'1 x LIC-MV-SEN-xYR (x = 1, 3, 5, 7, 10 years)'}</p>;
    } else {
      return null;
    }
  }

  renderDisplayLicenseSKU() {
    if (this.state.mvDisplay === true && this.state.mvDisplayLicenseTerm != null) {
      return <p>{'1 x LIC-DISPLAY-' + this.state.mvDisplayLicenseTerm + 'Y'}</p>;
    } else if (this.state.mvDisplay === true) {
      return <p>1 x LIC-DISPLAY-xY (x = 1, 3, 5, 7, 10 years)</p>;
    } else {
      return null;
    }
  }

  renderSwitchModel(model) {
    return (<div className={model + ' switchPictureContainer'}>
      <div onClick={this.state.switchModel != model
        ? () => this.setState({ switchModel: model, clickedSwitchModel: null, clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null })
        : () => this.setState({ switchModel: null, clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null })} className={'switchModel' + model}>
        <h6 className={this.state.switchModel == model
          ? 'selectedSwitchHeader'
          : ''}>{model}</h6>
        {
          model == 'MV2'
            ? <img src={'/img/mv/' + model + '.png'} alt={model} className='modelIconXSmall modelIcon' />
            : model == 'MV52'
              ? <img src={'/img/mv/' + model + '.png'} alt={model} className='modelIconHalf modelIcon' />
              : model == 'MV72' || model == 'MV72X' || model == 'MV73M' || model == 'MV73X'
                ? <img src={'/img/mv/' + model + '.png'} alt={model} className='modelIconMedium modelIcon' />
                : model == 'MV12N' || model == 'MV12W' || model == 'MV12WE'
                  ? <img src={'/img/mv/' + model + '.png'} alt={model} className='modelIconSmall modelIcon' />
                  : <img src={'/img/mv/' + model + '.png'} alt={model} className='modelIconSmall modelIcon' />
        }
      </div>

      <div className={'switchInfo'}>
        {
          this.state.switchModel == 'MV2' && model == this.state.switchModel
            ? <div>
              <div className='switchInfoSelected'>
                {this.renderSwitchInfo('Environment', 'Indoor')}
                {this.renderSwitchInfo('Storage', 'N/A')}
                {this.renderSwitchInfo('Resolution', '1080p')}
                {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                {this.renderSwitchInfo('Horizontal Field of View', '103°')}

                {this.renderSwitchInfo('Please select a power option', '')}
                <div className='antennaPicsContainerContainer'>
                  {this.renderAccessoryInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                  {this.renderAccessoryInfo(ethPower.model, '1 x ' + ethPower.sku, ethPower.description)}
                </div>
                <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>
                <div>
                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                  <p>1 x MV2-HW</p>
                  {this.renderLicenseSKU()}
                  {this.renderCALicenseSKU()}
                  {this.renderSenseLicenseSKU()}
                  {this.renderDisplayLicenseSKU()}
                  <p>{this.state.clickedAccessorySku}</p>
                </div>
              </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv2-cloud-managed-smart-cameras-datasheet/?file">Datasheet</a>
                <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV2_Installation_Guide">Installation</a>
              </div>
            </div>
            : this.state.switchModel == 'MV13' && model == this.state.switchModel
              ? <div>
                <div className='switchInfoSelected'>
                  {this.renderSwitchInfo('Environment', 'Indoor')}
                  {this.renderSwitchInfo('Storage', '256GB')}
                  {this.renderSwitchInfo('Resolution', '3840x2160')}
                  {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                  {this.renderSwitchInfo('Horizontal Field of View', '101°')}
                  {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                    <thead>
                      <tr>
                        <th>Quality</th>
                        <th>Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Standard</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>Enhanced</td>
                        <td>20</td>
                      </tr>
                      <tr>
                        <td>Ultra</td>
                        <td>15</td>
                      </tr>
                    </tbody>
                  </table>
                  {this.renderSwitchInfo('Compatible mounts', '')}
                  <div className='antennaPicsContainerContainer'>
                    {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                    {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                    {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                    {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                    {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                    {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                    {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                  </div>
                  <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                  <p>1 x MV13-HW</p>
                  {this.renderLicenseSKU()}
                  {this.renderCALicenseSKU()}
                  {this.renderSenseLicenseSKU()}
                  {this.renderDisplayLicenseSKU()}
                  <p>{this.state.clickedAccessorySku}</p>
                </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV13_Datasheet">Datasheet</a>
                  <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV13_Installation_Guide">Installation</a>
                </div>
              </div>

              : this.state.switchModel == 'MV13M' && model == this.state.switchModel
                ? <div>
                  <div className='switchInfoSelected'>
                    {this.renderSwitchInfo('Environment', 'Indoor')}
                    {this.renderSwitchInfo('Storage', '512GB')}
                    {this.renderSwitchInfo('Resolution', '3840x2160')}
                    {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                    {this.renderSwitchInfo('Horizontal Field of View', '101°')}
                    {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                      <thead>
                        <tr>
                          <th>Quality</th>
                          <th>Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Standard</td>
                          <td>60</td>
                        </tr>
                        <tr>
                          <td>Enhanced</td>
                          <td>45</td>
                        </tr>
                        <tr>
                          <td>Ultra</td>
                          <td>30</td>
                        </tr>
                      </tbody>
                    </table>
                    {this.renderSwitchInfo('Compatible mounts', '')}
                    <div className='antennaPicsContainerContainer'>
                      {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                      {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                      {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                      {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                      {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                      {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                      {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                    </div>
                    <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                    <p>1 x MV13M-HW</p>
                    {this.renderLicenseSKU()}
                    {this.renderCALicenseSKU()}
                    {this.renderSenseLicenseSKU()}
                    {this.renderDisplayLicenseSKU()}
                    <p>{this.state.clickedAccessorySku}</p>
                  </div>
                  <div className='datasheetContainer'>
                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV13_Datasheet">Datasheet</a>
                    <p className='datasheetDivider'> | </p>
                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV13_Installation_Guide">Installation</a>
                  </div>
                </div>

                : this.state.switchModel == 'MV33' && model == this.state.switchModel
                  ? <div>
                    <div className='switchInfoSelected'>
                      {this.renderSwitchInfo('Environment', 'Indoor')}
                      {this.renderSwitchInfo('Storage', '256GB')}
                      {this.renderSwitchInfo('Resolution', '2880x2880')}
                      {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                      {this.renderSwitchInfo('Horizontal Field of View', '180°')}
                      {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                        <thead>
                          <tr>
                            <th>Quality</th>
                            <th>Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Standard</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>Enhanced</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>Ultra</td>
                            <td>15</td>
                          </tr>
                        </tbody>
                      </table>
                      {this.renderSwitchInfo('Compatible mounts', '')}
                      <div className='antennaPicsContainerContainer'>
                        {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                        {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                        {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                        {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                        {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                        {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                        {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                      </div>
                      <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                      <p>1 x MV33-HW</p>
                      {this.renderLicenseSKU()}
                      {this.renderCALicenseSKU()}
                      {this.renderSenseLicenseSKU()}
                      {this.renderDisplayLicenseSKU()}
                      <p>{this.state.clickedAccessorySku}</p>
                    </div>
                    <div className='datasheetContainer'>
                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV33_Datasheet">Datasheet</a>
                      <p className='datasheetDivider'> | </p>
                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV33_Installation_Guide">Installation</a>
                    </div>
                  </div>
                  : this.state.switchModel == 'MV33M' && model == this.state.switchModel
                    ? <div>
                      <div className='switchInfoSelected'>
                        {this.renderSwitchInfo('Environment', 'Indoor')}
                        {this.renderSwitchInfo('Storage', '512GB')}
                        {this.renderSwitchInfo('Resolution', '2880x2880')}
                        {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                        {this.renderSwitchInfo('Horizontal Field of View', '180°')}
                        {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                          <thead>
                            <tr>
                              <th>Quality</th>
                              <th>Days</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Standard</td>
                              <td>60</td>
                            </tr>
                            <tr>
                              <td>Enhanced</td>
                              <td>45</td>
                            </tr>
                            <tr>
                              <td>Ultra</td>
                              <td>30</td>
                            </tr>
                          </tbody>
                        </table>
                        {this.renderSwitchInfo('Compatible mounts', '')}
                        <div className='antennaPicsContainerContainer'>
                          {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                          {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                          {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                          {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                          {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                          {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                          {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                        </div>
                        <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                        <p>1 x MV33M-HW</p>
                        {this.renderLicenseSKU()}
                        {this.renderCALicenseSKU()}
                        {this.renderSenseLicenseSKU()}
                        {this.renderDisplayLicenseSKU()}
                        <p>{this.state.clickedAccessorySku}</p>
                      </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV33_Datasheet">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV33_Installation_Guide">Installation</a>
                      </div>
                    </div>
                    : this.state.switchModel == 'MV93' && model == this.state.switchModel
                      ? <div>
                        <div className='switchInfoSelected'>
                          {this.renderSwitchInfo('Environment', 'Outdoor')}
                          {this.renderSwitchInfo('Storage', '256GB')}
                          {this.renderSwitchInfo('Resolution', '2112x2112')}
                          {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                          {this.renderSwitchInfo('Horizontal Field of View', '180°')}
                          {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                            <thead>
                              <tr>
                                <th>Quality</th>
                                <th>Days</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Standard</td>
                                <td>30</td>
                              </tr>
                              <tr>
                                <td>Enhanced</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>Ultra</td>
                                <td>15</td>
                              </tr>
                            </tbody>
                          </table>
                          {this.renderSwitchInfo('Compatible mounts', '')}
                          <div className='antennaPicsContainerContainer'>
                            {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                            {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                            {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                            {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                            {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                            {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                            {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                          </div>
                          <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                          <p>1 x MV93-HW</p>
                          {this.renderLicenseSKU()}
                          {this.renderCALicenseSKU()}
                          {this.renderSenseLicenseSKU()}
                          {this.renderDisplayLicenseSKU()}
                          <p>{this.state.clickedAccessorySku}</p>
                        </div>
                        <div className='datasheetContainer'>
                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv93-cloud-managed-smart-cameras/?file">Datasheet</a>
                          <p className='datasheetDivider'> | </p>
                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV93_Installation_Guide">Installation</a>
                        </div>
                      </div>
                      : this.state.switchModel == 'MV93M' && model == this.state.switchModel
                        ? <div>
                          <div className='switchInfoSelected'>
                            {this.renderSwitchInfo('Environment', 'Outdoor')}
                            {this.renderSwitchInfo('Storage', '512GB')}
                            {this.renderSwitchInfo('Resolution', '2112x2112')}
                            {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                            {this.renderSwitchInfo('Horizontal Field of View', '180°')}
                            {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                              <thead>
                                <tr>
                                  <th>Quality</th>
                                  <th>Days</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Standard</td>
                                  <td>60</td>
                                </tr>
                                <tr>
                                  <td>Enhanced</td>
                                  <td>45</td>
                                </tr>
                                <tr>
                                  <td>Ultra</td>
                                  <td>30</td>
                                </tr>
                              </tbody>
                            </table>
                            {this.renderSwitchInfo('Compatible mounts', '')}
                            <div className='antennaPicsContainerContainer'>
                              {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                              {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                              {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                              {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                              {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                              {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                              {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                            </div>
                            <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                            <p>1 x MV93M-HW</p>
                            {this.renderLicenseSKU()}
                            {this.renderCALicenseSKU()}
                            {this.renderSenseLicenseSKU()}
                            {this.renderDisplayLicenseSKU()}
                            <p>{this.state.clickedAccessorySku}</p>
                          </div>
                          <div className='datasheetContainer'>
                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv93-cloud-managed-smart-cameras/?file">Datasheet</a>
                            <p className='datasheetDivider'> | </p>
                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV93_Installation_Guide">Installation</a>
                          </div>
                        </div>
                        : this.state.switchModel == 'MV93X' && model == this.state.switchModel
                          ? <div>
                            <div className='switchInfoSelected'>
                              {this.renderSwitchInfo('Environment', 'Outdoor')}
                              {this.renderSwitchInfo('Storage', '1TB')}
                              {this.renderSwitchInfo('Resolution', '2880x2880')}
                              {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                              {this.renderSwitchInfo('Horizontal Field of View', '180°')}
                              {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                <thead>
                                  <tr>
                                    <th>Quality</th>
                                    <th>Days</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Standard</td>
                                    <td>120</td>
                                  </tr>
                                  <tr>
                                    <td>Enhanced</td>
                                    <td>90</td>
                                  </tr>
                                  <tr>
                                    <td>Ultra</td>
                                    <td>60</td>
                                  </tr>
                                </tbody>
                              </table>
                              {this.renderSwitchInfo('Compatible mounts', '')}
                              <div className='antennaPicsContainerContainer'>
                                {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                              </div>
                              <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                              <p>1 x MV93X-HW</p>
                              {this.renderLicenseSKU()}
                              {this.renderCALicenseSKU()}
                              {this.renderSenseLicenseSKU()}
                              {this.renderDisplayLicenseSKU()}
                              <p>{this.state.clickedAccessorySku}</p>
                            </div>
                            <div className='datasheetContainer'>
                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv93-cloud-managed-smart-cameras/?file">Datasheet</a>
                              <p className='datasheetDivider'> | </p>
                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV93_Installation_Guide">Installation</a>
                            </div>
                          </div>
                          : this.state.switchModel == 'MV63' && model == this.state.switchModel
                            ? <div>
                              <div className='switchInfoSelected'>
                                {this.renderSwitchInfo('Environment', 'Outdoor')}
                                {this.renderSwitchInfo('Storage', '256GB')}
                                {this.renderSwitchInfo('Resolution', '4MP')}
                                {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                {this.renderSwitchInfo('Horizontal Field of View', '102°')}
                                {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                  <thead>
                                    <tr>
                                      <th>Quality</th>
                                      <th>Days</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Standard</td>
                                      <td>30</td>
                                    </tr>
                                    <tr>
                                      <td>Enhanced</td>
                                      <td>20</td>
                                    </tr>
                                    <tr>
                                      <td>Ultra</td>
                                      <td>15</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {this.renderSwitchInfo('Compatible mounts', '')}
                                <div className='antennaPicsContainerContainer'>
                                  {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                  {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                  {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                  {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                  {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                  {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                  {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                </div>
                                <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                <p>1 x MV63-HW</p>
                                {this.renderLicenseSKU()}
                                {this.renderCALicenseSKU()}
                                {this.renderSenseLicenseSKU()}
                                {this.renderDisplayLicenseSKU()}
                                <p>{this.state.clickedAccessorySku}</p>
                              </div>
                              <div className='datasheetContainer'>
                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv63-cloud-managed-smart-camera/?file">Datasheet</a>
                                <p className='datasheetDivider'> | </p>
                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV63_Series_Installation_Guide">Installation</a>
                              </div>
                            </div>

                            : this.state.switchModel == 'MV63M' && model == this.state.switchModel
                              ? <div>
                                <div className='switchInfoSelected'>
                                  {this.renderSwitchInfo('Environment', 'Outdoor')}
                                  {this.renderSwitchInfo('Storage', '512GB')}
                                  {this.renderSwitchInfo('Resolution', '4MP')}
                                  {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                  {this.renderSwitchInfo('Horizontal Field of View', '102°')}
                                  {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                    <thead>
                                      <tr>
                                        <th>Quality</th>
                                        <th>Days</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Standard</td>
                                        <td>60</td>
                                      </tr>
                                      <tr>
                                        <td>Enhanced</td>
                                        <td>45</td>
                                      </tr>
                                      <tr>
                                        <td>Ultra</td>
                                        <td>30</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {this.renderSwitchInfo('Compatible mounts', '')}
                                  <div className='antennaPicsContainerContainer'>
                                    {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                    {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                    {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                    {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                    {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                    {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                    {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                  </div>
                                  <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                  <p>1 x MV63M-HW</p>
                                  {this.renderLicenseSKU()}
                                  {this.renderCALicenseSKU()}
                                  {this.renderSenseLicenseSKU()}
                                  {this.renderDisplayLicenseSKU()}
                                  <p>{this.state.clickedAccessorySku}</p>
                                </div>
                                <div className='datasheetContainer'>
                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv63-cloud-managed-smart-camera/?file">Datasheet</a>
                                  <p className='datasheetDivider'> | </p>
                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV63_Series_Installation_Guide">Installation</a>
                                </div>
                              </div>

                              : this.state.switchModel == 'MV63X' && model == this.state.switchModel
                                ? <div>
                                  <div className='switchInfoSelected'>
                                    {this.renderSwitchInfo('Environment', 'Outdoor')}
                                    {this.renderSwitchInfo('Storage', '1TB')}
                                    {this.renderSwitchInfo('Resolution', '4K')}
                                    {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                    {this.renderSwitchInfo('Horizontal Field of View', '102°')}
                                    {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                      <thead>
                                        <tr>
                                          <th>Quality</th>
                                          <th>Days</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Standard</td>
                                          <td>120</td>
                                        </tr>
                                        <tr>
                                          <td>Enhanced</td>
                                          <td>90</td>
                                        </tr>
                                        <tr>
                                          <td>Ultra</td>
                                          <td>60</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    {this.renderSwitchInfo('Compatible mounts', '')}
                                    <div className='antennaPicsContainerContainer'>
                                      {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                      {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                      {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                      {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                      {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                      {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                      {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                    </div>
                                    <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                    <p>1 x MV63X-HW</p>
                                    {this.renderLicenseSKU()}
                                    {this.renderCALicenseSKU()}
                                    {this.renderSenseLicenseSKU()}
                                    {this.renderDisplayLicenseSKU()}
                                    <p>{this.state.clickedAccessorySku}</p>
                                  </div>
                                  <div className='datasheetContainer'>
                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv63-cloud-managed-smart-camera/?file">Datasheet</a>
                                    <p className='datasheetDivider'> | </p>
                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV63_Series_Installation_Guide">Installation</a>
                                  </div>
                                </div>
                                : this.state.switchModel == 'MV32' && model == this.state.switchModel
                                  ? <div>
                                    <div className='switchInfoSelected'>
                                      {this.renderSwitchInfo('Environment', 'Indoor')}
                                      {this.renderSwitchInfo('Storage', '256GB')}
                                      {this.renderSwitchInfo('Resolution', '2058x2058')}
                                      {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                      {this.renderSwitchInfo('Horizontal Field of View', '180°')}
                                      {this.renderSwitchInfo('Compatible mounts', '')}
                                      <div className='antennaPicsContainerContainer'>
                                        {this.renderAccessoryInfo(wallMountArm.model, '1 x ' + wallMountArm.sku, wallMountArm.description)}
                                        {this.renderAccessoryInfo(telescopeMount.model, '1 x ' + telescopeMount.sku, telescopeMount.description)}
                                        {this.renderAccessoryInfo(trailMount.model, '1 x ' + trailMount.sku, trailMount.description)}
                                      </div>
                                      <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                      <p>1 x MV32-HW</p>
                                      {this.renderLicenseSKU()}
                                      {this.renderCALicenseSKU()}
                                      {this.renderSenseLicenseSKU()}
                                      {this.renderDisplayLicenseSKU()}
                                      <p>{this.state.clickedAccessorySku}</p>
                                    </div>
                                    <div className='datasheetContainer'>
                                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv32-datasheet/?file">Datasheet</a>
                                      <p className='datasheetDivider'> | </p>
                                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV32_Installation_Guide">Installation</a>
                                    </div>
                                  </div>

                                  : this.state.switchModel == 'MV22X' && model == this.state.switchModel
                                    ? <div>
                                      <div className='switchInfoSelected'>
                                        {this.renderSwitchInfo('Environment', 'Indoor')}
                                        {this.renderSwitchInfo('Storage', '512GB')}
                                        {this.renderSwitchInfo('Resolution', '4MP')}
                                        {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                        {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                        {this.renderSwitchInfo('Compatible mounts', '')}
                                        <div className='antennaPicsContainerContainer'>
                                          {this.renderAccessoryInfo(lshapewallmountarm.model, '1 x ' + lshapewallmountarm.sku, lshapewallmountarm.description)}
                                          {this.renderAccessoryInfo(trailMount.model, '1 x ' + trailMount.sku, trailMount.description)}
                                          {this.renderAccessoryInfo(poleMount.model, '1 x ' + poleMount.sku, poleMount.description)}
                                        </div>
                                        <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                        <p>1 x MV22X-HW</p>
                                        {this.renderLicenseSKU()}
                                        {this.renderCALicenseSKU()}
                                        {this.renderSenseLicenseSKU()}
                                        {this.renderDisplayLicenseSKU()}
                                        <p>{this.state.clickedAccessorySku}</p>
                                      </div>
                                      <div className='datasheetContainer'>
                                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv22-datasheet/?file">Datasheet</a>
                                        <p className='datasheetDivider'> | </p>
                                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV22_Series_Installation_Guide">Installation</a>
                                      </div>
                                    </div>

                                    : this.state.switchModel == 'MV22' && model == this.state.switchModel
                                      ? <div>
                                        <div className='switchInfoSelected'>
                                          {this.renderSwitchInfo('Environment', 'Indoor')}
                                          {this.renderSwitchInfo('Storage', '256GB')}
                                          {this.renderSwitchInfo('Resolution', '1080p')}
                                          {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                          {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                          {this.renderSwitchInfo('Compatible mounts', '')}
                                          <div className='antennaPicsContainerContainer'>
                                            {this.renderAccessoryInfo(lshapewallmountarm.model, '1 x ' + lshapewallmountarm.sku, lshapewallmountarm.description)}
                                            {this.renderAccessoryInfo(trailMount.model, '1 x ' + trailMount.sku, trailMount.description)}
                                            {this.renderAccessoryInfo(poleMount.model, '1 x ' + poleMount.sku, poleMount.description)}
                                          </div>
                                          <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                          <p>1 x MV22-HW</p>
                                          {this.renderLicenseSKU()}
                                          {this.renderCALicenseSKU()}
                                          {this.renderSenseLicenseSKU()}
                                          {this.renderDisplayLicenseSKU()}
                                          <p>{this.state.clickedAccessorySku}</p>
                                        </div>
                                        <div className='datasheetContainer'>
                                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv22-datasheet/?file">Datasheet</a>
                                          <p className='datasheetDivider'> | </p>
                                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV22_Series_Installation_Guide">Installation</a>
                                        </div>
                                      </div>

                                      : this.state.switchModel == 'MV23M' && model == this.state.switchModel
                                        ? <div>
                                          <div className='switchInfoSelected'>
                                            {this.renderSwitchInfo('Environment', 'Indoor')}
                                            {this.renderSwitchInfo('Storage', '512GB')}
                                            {this.renderSwitchInfo('Resolution', '4K')}
                                            {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                            {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                            {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                              <thead>
                                                <tr>
                                                  <th>Quality</th>
                                                  <th>Days</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Standard</td>
                                                  <td>60</td>
                                                </tr>
                                                <tr>
                                                  <td>Enhanced</td>
                                                  <td>45</td>
                                                </tr>
                                                <tr>
                                                  <td>Ultra</td>
                                                  <td>30</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            {this.renderSwitchInfo('Compatible mounts', '')}
                                            <div className='antennaPicsContainerContainer'>
                                              {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                              {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                              {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                              {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                              {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                              {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                              {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                            </div>
                                            <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                            <p>1 x MV23M-HW</p>
                                            {this.renderLicenseSKU()}
                                            {this.renderCALicenseSKU()}
                                            {this.renderSenseLicenseSKU()}
                                            {this.renderDisplayLicenseSKU()}
                                            <p>{this.state.clickedAccessorySku}</p>
                                          </div>
                                          <div className='datasheetContainer'>
                                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV23_Series_Datasheet">Datasheet</a>
                                            <p className='datasheetDivider'> | </p>
                                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV23_Installation_Guide">Installation</a>
                                          </div>
                                        </div>

                                        : this.state.switchModel == 'MV23X' && model == this.state.switchModel
                                          ? <div>
                                            <div className='switchInfoSelected'>
                                              {this.renderSwitchInfo('Environment', 'Indoor')}
                                              {this.renderSwitchInfo('Storage', '1TB')}
                                              {this.renderSwitchInfo('Resolution', '4K')}
                                              {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                              {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                              {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                                <thead>
                                                  <tr>
                                                    <th>Quality</th>
                                                    <th>Days</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>Standard</td>
                                                    <td>120</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Enhanced</td>
                                                    <td>90</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Ultra</td>
                                                    <td>60</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              {this.renderSwitchInfo('Compatible mounts', '')}
                                              <div className='antennaPicsContainerContainer'>
                                                {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                                {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                                {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                                {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                                {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                                {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                                {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                              </div>
                                              <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                              <p>1 x MV23X-HW</p>
                                              {this.renderLicenseSKU()}
                                              {this.renderCALicenseSKU()}
                                              {this.renderSenseLicenseSKU()}
                                              {this.renderDisplayLicenseSKU()}
                                              <p>{this.state.clickedAccessorySku}</p>
                                            </div>
                                            <div className='datasheetContainer'>
                                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV23_Series_Datasheet">Datasheet</a>
                                              <p className='datasheetDivider'> | </p>
                                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV23_Installation_Guide">Installation</a>
                                            </div>
                                          </div>

                                          : this.state.switchModel == 'MV12WE' && model == this.state.switchModel
                                            ? <div>
                                              <div className='switchInfoSelected'>
                                                {this.renderSwitchInfo('Environment', 'Indoor')}
                                                {this.renderSwitchInfo('Storage', '128GB')}
                                                {this.renderSwitchInfo('Resolution', '1080p')}
                                                {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                                {this.renderSwitchInfo('Horizontal Field of View', '114°')}
                                                {this.renderSwitchInfo('Compatible mounts', '')}
                                                <div className='antennaPicsContainerContainer'>
                                                  {this.renderAccessoryInfo(wallMountArm.model, '1 x ' + wallMountArm.sku, wallMountArm.description)}
                                                  {this.renderAccessoryInfo(telescopeMount.model, '1 x ' + telescopeMount.sku, telescopeMount.description)}
                                                  {this.renderAccessoryInfo(trailMount.model, '1 x ' + trailMount.sku, trailMount.description)}
                                                </div>
                                                <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                <p>1 x MV12WE-HW</p>
                                                {this.renderLicenseSKU()}
                                                {this.renderCALicenseSKU()}
                                                {this.renderSenseLicenseSKU()}
                                                {this.renderDisplayLicenseSKU()}
                                                <p>{this.state.clickedAccessorySku}</p>
                                              </div>
                                              <div className='datasheetContainer'>
                                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv12-series-datasheet/?file">Datasheet</a>
                                                <p className='datasheetDivider'> | </p>
                                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV12_Installation_Guide">Installation</a>
                                              </div>
                                            </div>
                                            : this.state.switchModel == 'MV12W' && model == this.state.switchModel
                                              ? <div>
                                                <div className='switchInfoSelected'>
                                                  {this.renderSwitchInfo('Environment', 'Indoor')}
                                                  {this.renderSwitchInfo('Storage', '256GB')}
                                                  {this.renderSwitchInfo('Resolution', '1080p')}
                                                  {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                                  {this.renderSwitchInfo('Horizontal Field of View', '114°')}
                                                  {this.renderSwitchInfo('Compatible mounts', '')}
                                                  <div className='antennaPicsContainerContainer'>
                                                    {this.renderAccessoryInfo(wallMountArm.model, '1 x ' + wallMountArm.sku, wallMountArm.description)}
                                                    {this.renderAccessoryInfo(telescopeMount.model, '1 x ' + telescopeMount.sku, telescopeMount.description)}
                                                    {this.renderAccessoryInfo(trailMount.model, '1 x ' + trailMount.sku, trailMount.description)}
                                                  </div>
                                                  <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                  <p>1 x MV12W-HW</p>
                                                  {this.renderLicenseSKU()}
                                                  {this.renderCALicenseSKU()}
                                                  {this.renderSenseLicenseSKU()}
                                                  {this.renderDisplayLicenseSKU()}
                                                  <p>{this.state.clickedAccessorySku}</p>
                                                </div>
                                                <div className='datasheetContainer'>
                                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv12-series-datasheet/?file">Datasheet</a>
                                                  <p className='datasheetDivider'> | </p>
                                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV12_Installation_Guide">Installation</a>
                                                </div>
                                              </div>
                                              : this.state.switchModel == 'MV12N' && model == this.state.switchModel
                                                ? <div>
                                                  <div className='switchInfoSelected'>
                                                    {this.renderSwitchInfo('Environment', 'Indoor')}
                                                    {this.renderSwitchInfo('Storage', '256GB')}
                                                    {this.renderSwitchInfo('Resolution', '1080p')}
                                                    {this.renderSwitchInfo('Zoom', 'Digital Zoom')}
                                                    {this.renderSwitchInfo('Horizontal Field of View', '73°')}
                                                    {this.renderSwitchInfo('Compatible mounts', '')}
                                                    <div className='antennaPicsContainerContainer'>
                                                      {this.renderAccessoryInfo(wallMountArm.model, '1 x ' + wallMountArm.sku, wallMountArm.description)}
                                                      {this.renderAccessoryInfo(telescopeMount.model, '1 x ' + telescopeMount.sku, telescopeMount.description)}
                                                      {this.renderAccessoryInfo(trailMount.model, '1 x ' + trailMount.sku, trailMount.description)}
                                                    </div>
                                                    <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                    <p>1 x MV12N-HW</p>
                                                    {this.renderLicenseSKU()}
                                                    {this.renderCALicenseSKU()}
                                                    {this.renderSenseLicenseSKU()}
                                                    {this.renderDisplayLicenseSKU()}
                                                    <p>{this.state.clickedAccessorySku}</p>
                                                  </div>
                                                  <div className='datasheetContainer'>
                                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv12-series-datasheet/?file">Datasheet</a>
                                                    <p className='datasheetDivider'> | </p>
                                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV12_Installation_Guide">Installation</a>
                                                  </div>
                                                </div>
                                                : this.state.switchModel == 'MV72X' && model == this.state.switchModel
                                                  ? <div>
                                                    <div className='switchInfoSelected'>
                                                      {this.renderSwitchInfo('Environment', 'Outdoor (IP67, IK10+)')}
                                                      {this.renderSwitchInfo('Storage', '512GB')}
                                                      {this.renderSwitchInfo('Resolution', '4MP')}
                                                      {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                                      {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                                      {this.renderSwitchInfo('Compatible mounts', '')}
                                                      <div className='antennaPicsContainerContainer'>
                                                        {this.renderAccessoryInfo(wallMountArm72.model, '1 x ' + wallMountArm72.sku, wallMountArm72.description)}
                                                        {this.renderAccessoryInfo(poleMount.model, '1 x ' + poleMount.sku, poleMount.description)}
                                                      </div>
                                                      <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                      <p>1 x MV72X-HW</p>
                                                      {this.renderLicenseSKU()}
                                                      {this.renderCALicenseSKU()}
                                                      {this.renderSenseLicenseSKU()}
                                                      {this.renderDisplayLicenseSKU()}
                                                      <p>{this.state.clickedAccessorySku}</p>
                                                    </div>
                                                    <div className='datasheetContainer'>
                                                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv72-datasheet/?file">Datasheet</a>
                                                      <p className='datasheetDivider'> | </p>
                                                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV72_Series_Installation_Guide">Installation</a>
                                                    </div>
                                                  </div>
                                                  : this.state.switchModel == 'MV72' && model == this.state.switchModel
                                                    ? <div>
                                                      <div className='switchInfoSelected'>
                                                        {this.renderSwitchInfo('Environment', 'Outdoor (IP67, IK10+)')}
                                                        {this.renderSwitchInfo('Storage', '256GB')}
                                                        {this.renderSwitchInfo('Resolution', '1080p')}
                                                        {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                                        {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                                        {this.renderSwitchInfo('Compatible mounts', '')}
                                                        <div className='antennaPicsContainerContainer'>
                                                          {this.renderAccessoryInfo(wallMountArm72.model, '1 x ' + wallMountArm72.sku, wallMountArm72.description)}
                                                          {this.renderAccessoryInfo(poleMount.model, '1 x ' + poleMount.sku, poleMount.description)}
                                                        </div>
                                                        <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                        <p>1 x MV72-HW</p>
                                                        {this.renderLicenseSKU()}
                                                        {this.renderCALicenseSKU()}
                                                        {this.renderSenseLicenseSKU()}
                                                        {this.renderDisplayLicenseSKU()}
                                                        <p>{this.state.clickedAccessorySku}</p>
                                                      </div>
                                                      <div className='datasheetContainer'>
                                                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv72-datasheet/?file">Datasheet</a>
                                                        <p className='datasheetDivider'> | </p>
                                                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV72_Series_Installation_Guide">Installation</a>
                                                      </div>
                                                    </div>

                                                    : this.state.switchModel == 'MV73M' && model == this.state.switchModel
                                                      ? <div>
                                                        <div className='switchInfoSelected'>
                                                          {this.renderSwitchInfo('Environment', 'Outdoor')}
                                                          {this.renderSwitchInfo('Storage', '512GB')}
                                                          {this.renderSwitchInfo('Resolution', '4K')}
                                                          {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                                          {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                                          {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                                            <thead>
                                                              <tr>
                                                                <th>Quality</th>
                                                                <th>Days</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr>
                                                                <td>Standard</td>
                                                                <td>60</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Enhanced</td>
                                                                <td>45</td>
                                                              </tr>
                                                              <tr>
                                                                <td>Ultra</td>
                                                                <td>30</td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                          {this.renderSwitchInfo('Compatible mounts', '')}
                                                          <div className='antennaPicsContainerContainer'>
                                                            {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                                            {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                                            {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                                            {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                                            {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                                            {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                                            {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                                          </div>
                                                          <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                          <p>1 x MV73M-HW</p>
                                                          {this.renderLicenseSKU()}
                                                          {this.renderCALicenseSKU()}
                                                          {this.renderSenseLicenseSKU()}
                                                          {this.renderDisplayLicenseSKU()}
                                                          <p>{this.state.clickedAccessorySku}</p>
                                                        </div>
                                                        <div className='datasheetContainer'>
                                                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV73_Series_Datasheet">Datasheet</a>
                                                          <p className='datasheetDivider'> | </p>
                                                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV73_Installation_Guide">Installation</a>
                                                        </div>
                                                      </div>

                                                      : this.state.switchModel == 'MV73X' && model == this.state.switchModel
                                                        ? <div>
                                                          <div className='switchInfoSelected'>
                                                            {this.renderSwitchInfo('Environment', 'Outdoor')}
                                                            {this.renderSwitchInfo('Storage', '1TB')}
                                                            {this.renderSwitchInfo('Resolution', '4K')}
                                                            {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                                            {this.renderSwitchInfo('Horizontal Field of View', '36° - 112°')}
                                                            {this.renderSwitchInfo('Smart Retention', '')}
                  <table className="smart-retention-table">
                                                              <thead>
                                                                <tr>
                                                                  <th>Quality</th>
                                                                  <th>Days</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                <tr>
                                                                  <td>Standard</td>
                                                                  <td>120</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Enhanced</td>
                                                                  <td>90</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>Ultra</td>
                                                                  <td>60</td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                            {this.renderSwitchInfo('Compatible mounts', '')}
                                                            <div className='antennaPicsContainerContainer'>
                                                              {this.renderAccessoryInfo(poleMountGen3.model, '1 x ' + poleMountGen3.sku, poleMountGen3.description)}
                                                              {this.renderAccessoryInfo(lshapewallmountarmGen3.model, '1 x ' + lshapewallmountarmGen3.sku, lshapewallmountarmGen3.description)}
                                                              {this.renderAccessoryInfo(wallMountArmGen3.model, '1 x ' + wallMountArmGen3.sku, wallMountArmGen3.description)}
                                                              {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                                              {this.renderAccessoryInfo(trailMountGen3.model, '1 x ' + trailMountGen3.sku, trailMountGen3.description)}
                                                              {this.renderAccessoryInfo(telescopeMountGen3.model, '1 x ' + telescopeMountGen3.sku, telescopeMountGen3.description)}
                                                              {this.renderAccessoryInfo(cornerGen3.model, '1 x ' + cornerGen3.sku, cornerGen3.description)}
                                                            </div>
                                                            <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                            <p>1 x MV73X-HW</p>
                                                            {this.renderLicenseSKU()}
                                                            {this.renderCALicenseSKU()}
                                                            {this.renderSenseLicenseSKU()}
                                                            {this.renderDisplayLicenseSKU()}
                                                            <p>{this.state.clickedAccessorySku}</p>
                                                          </div>
                                                          <div className='datasheetContainer'>
                                                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/MV_Datasheets/MV73_Series_Datasheet">Datasheet</a>
                                                            <p className='datasheetDivider'> | </p>
                                                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV73_Installation_Guide">Installation</a>
                                                          </div>
                                                        </div>
                                                        : this.state.switchModel == 'MV52' && model == this.state.switchModel
                                                          ? <div>
                                                            <div className='switchInfoSelected'>
                                                              {this.renderSwitchInfo('Environment', 'Outdoor (IP67, IK10+)')}
                                                              {this.renderSwitchInfo('Storage', '1TB')}
                                                              {this.renderSwitchInfo('Resolution', '4K')}
                                                              {this.renderSwitchInfo('Zoom', '3x Optical Zoom')}
                                                              {this.renderSwitchInfo('Horizontal Field of View', '12° - 37°')}
                                                              {this.renderSwitchInfo('Compatible mounts', '')}
                                                              <div className='antennaPicsContainerContainer'>
                                                                {this.renderAccessoryInfo(poleMount52.model, '1 x ' + poleMount52.sku, poleMount52.description)}
                                                                {this.renderAccessoryInfo(conduitGen3.model, '1 x ' + conduitGen3.sku, conduitGen3.description)}
                                                              </div>
                                                              <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                                                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                              <p>1 x MV52-HW</p>
                                                              {this.renderLicenseSKU()}
                                                              {this.renderCALicenseSKU()}
                                                              {this.renderSenseLicenseSKU()}
                                                              {this.renderDisplayLicenseSKU()}
                                                              <p>{this.state.clickedAccessorySku}</p>
                                                            </div>
                                                            <div className='datasheetContainer'>
                                                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mv52-cloud-managed-smart-cameras-datasheet/?file">Datasheet</a>
                                                              <p className='datasheetDivider'> | </p>
                                                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MV/Physical_Installation/MV52_Installation_Guide">Installation</a>
                                                            </div>
                                                          </div>

                                                          : ('')
        }
      </div>
    </div >)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ PICKING LOGIC @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  devicePickingLogic() {
    return (<div>
      {
        (<div>
          {
            devices.map(
              deviceModel => this.state.outdoor != null && deviceModel.outdoor != this.state.outdoor
                ? ''
                : this.state.resolution != null && deviceModel.resolution1 < this.state.resolution
                  ? ''

                  // Smart retention quality
                  : this.state.smartQuality == 'Standard' && deviceModel.qualityStandard == '0'
                    ? ''
                    : this.state.smartQuality == 'Enhanced' && deviceModel.qualityEnhanced == '0'
                      ? ''
                      : this.state.smartQuality == 'Ultra' && deviceModel.qualityUltra == '0'
                        ? ''

                        // Smart retention storage days
                        : this.state.smartRetentionDays != null && this.state.smartQuality == null && deviceModel.qualityStandard < this.state.smartRetentionDays
                          ? ''
                          : this.state.smartRetentionDays != null && this.state.smartQuality == 'Standard' && deviceModel.qualityStandard < this.state.smartRetentionDays
                            ? ''
                            : this.state.smartRetentionDays != null && this.state.smartQuality == 'Enhanced' && deviceModel.qualityEnhanced < this.state.smartRetentionDays
                              ? ''
                              : this.state.smartRetentionDays != null && this.state.smartQuality == 'Ultra' && deviceModel.qualityUltra < this.state.smartRetentionDays
                                ? ''

                                : this.state.storage != null && deviceModel.storage < this.state.storage
                                  ? ''
                                  : this.state.ir != null && deviceModel.ir != this.state.ir
                                    ? ''
                                    : this.state.opticZoom != null && deviceModel.opticZoom != this.state.opticZoom
                                      ? ''
                                      : this.state.peopleDetection != null && deviceModel.peopleDetection != this.state.peopleDetection
                                        ? ''
                                        : this.state.advancedPeopleCounter != null && deviceModel.advancedPeopleCounter != this.state.advancedPeopleCounter
                                          ? ''
                                          : this.state.vehicleDetection != null && deviceModel.vehicleDetection != this.state.vehicleDetection
                                            ? ''
                                            : this.state.attributeSearch != null && deviceModel.attributeSearch != this.state.attributeSearch
                                              ? ''
                                              : this.renderSwitchModel(deviceModel.model))
          }
        </div>)
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ BUBBLES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  optionBubbleLogic() {
    return (<div>
      {
        this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ licenseTerm: null })}>{this.state.licenseTerm + ' YR License'}</div>
          : null
      }
      {
        this.state.outdoor != null
          ? <div className='optionBubble' onClick={() => this.setState({ outdoor: null })}>{
            this.state.outdoor
              ? 'Outdoor'
              : 'Indoor'
          }</div>
          : null
      }
      {
        this.state.smartQuality != null
          ? <div className='optionBubble' onClick={() => this.setState({ smartQuality: null })}>{this.state.smartQuality}</div>
          : null
      }
      {
        this.state.storage >= 1000
          ? <div className='optionBubble' onClick={() => this.setState({ storage: null })}>{this.state.storage / 1000 + ' TB'}</div>
          : this.state.storage != null
            ? <div className='optionBubble' onClick={() => this.setState({ storage: null })}>{this.state.storage + ' GB'}</div>
            : null
      }
      {
        this.state.resolution != null
          ? <div className='optionBubble' onClick={() => this.setState({ resolution: null, resolutionBubbble: null })}>{this.state.resolutionBubble}</div>
          : null
      }
      {
        this.state.ir != null
          ? <div className='optionBubble' onClick={() => this.setState({ ir: null })}>{'IR Illumination'}</div>
          : null
      }

      {
        this.state.opticZoom != null
          ? <div className='optionBubble' onClick={() => this.setState({ opticZoom: null })}>{'Optical Zoom'}</div>
          : null
      }
      {
        this.state.peopleDetection != null
          ? <div className='optionBubble' onClick={() => this.setState({ peopleDetection: null })}>{'People Detection'}</div>
          : null
      }
      {
        this.state.advancedPeopleCounter != null
          ? <div className='optionBubble' onClick={() => this.setState({ advancedPeopleCounter: null })}>{'Advanced People Counter'}</div>
          : null
      }
      {
        this.state.vehicleDetection != null
          ? <div className='optionBubble' onClick={() => this.setState({ vehicleDetection: null })}>{'Vehicle Detection'}</div>
          : null
      }
      {
        this.state.attributeSearch != null
          ? <div className='optionBubble' onClick={() => this.setState({ attributeSearch: null })}>{'Attribute Search'}</div>
          : null
      }
      {
        this.state.mvCA == true && this.state.mvCADuration != null && this.state.cloudArchiveLicenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ cloudArchiveLicenseTerm: null, mvCADuration: null, mvCA: null })}>{this.state.mvCADuration + ' Day Cloud Archive: ' + this.state.cloudArchiveLicenseTerm + ' YR'}</div>
          : this.state.mvCA == true && this.state.mvCADuration != null
            ? <div className='optionBubble' onClick={() => this.setState({ cloudArchiveLicenseTerm: null, mvCADuration: null, mvCA: null })}>{this.state.mvCADuration + ' Day Cloud Archive'}</div>
            : this.state.mvCA == true
              ? <div className='optionBubble' onClick={() => this.setState({ cloudArchiveLicenseTerm: null, mvCADuration: null, mvCA: null })}>{'Cloud Archive'}</div>
              : null
      }
      {
        this.state.mvSense == true && this.state.mvSenseLicenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ mvSense: null, mvSenseLicenseTerm: null })}>{'MV Sense: ' + this.state.mvSenseLicenseTerm + ' YR'}</div>
          : this.state.mvSense == true
            ? <div className='optionBubble' onClick={() => this.setState({ mvSense: null, mvSenseLicenseTerm: null })}>{'MV Sense'}</div>
            : null
      }
      {
        this.state.mvDisplay == true && this.state.mvDisplayLicenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ mvDisplay: null, mvDisplayLicenseTerm: null })}>{'Meraki Display: ' + this.state.mvDisplayLicenseTerm + ' YR'}</div>
          : this.state.mvDisplay == true
            ? <div className='optionBubble' onClick={() => this.setState({ mvDisplay: null, mvDisplayLicenseTerm: null })}>{'Meraki Display'}</div>
            : null
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ OPTIONS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  handleChange(event) {
    this.setState({
      smartRetentionDays: event.target.value.replace(/\D/, "")
    });
  }

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })} className={this.state.licenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      {years}
    </div>)
  }

  renderOptionIndoor() {
    return (<div>
      <div onClick={(
      ) => this.state.outdoor != false
          ? this.setState({ outdoor: false })
          : this.setState({ outdoor: null })} className={this.state.outdoor == false
            ? 'optionClicked'
            : 'option'}>
        Indoor
      </div>
    </div>)
  }
  renderOptionOutdoor() {
    return (<div>
      <div onClick={(
      ) => this.state.outdoor != true
          ? this.setState({ outdoor: true })
          : this.setState({ outdoor: null })} className={this.state.outdoor
            ? 'optionClicked'
            : 'option'}>
        Outdoor
      </div>
    </div>)
  }

  renderSmartRetention() {
    return (<div>
      <div>
        {
          <form className="optionForm" onSubmit={(e) => {
            e.preventDefault();
          }}>
            <div class="optionGroup">
              <input className={this.state.smartRetentionDays != "" && this.state.smartRetentionDays != null
                ? "optionInputClicked"
                : "optionInput"
              } type="text" name="name" autocomplete="off" required="required" value={this.state.smartRetentionDays} onChange={this.handleChange} onFocus={() => this.setState({ devicesMain: true })} onBlur={() => this.setState({ devicesMain: false })} />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="optionLabel">Number of Days</label>
            </div>
          </form>
        }
      </div>
      {
        this.state.devicesMain == true
          ? (<div className="secondaryOptionsContainer">
            <h4>Smart Retention</h4>
            <h5>
              MV 6.0.2 is the minimum supported firmware version for Smart Retention. Smart Retention is available for the following camera models: MV13, MV13M, MV23M, MV23X, MV33, MV33M, MV63, MV63M, MV63X, MV73M, MV73X, MV93, MV93M, MV93X.
            </h5>
          </div>)
          : (<div></div>)
      }
    </div>);
  }

  renderSmartQualityOption(smartQuality, smartQualityDetails) {
    return (<div onClick={(
    ) => this.state.smartQuality != smartQuality
        ? this.setState({ smartQuality: smartQuality })
        : this.setState({ smartQuality: null })} className={this.state.smartQuality == smartQuality
          ? 'optionClicked'
          : 'option'}>

      <div className='leftSide'>{smartQuality}</div>
      <div className='rightSide'>{smartQualityDetails}</div>
    </div>)
  }

  renderOptionResolution(resolution, resolutionP) {
    return (<div onClick={(
    ) => this.state.resolution != resolutionP
        ? this.setState({ resolution: resolutionP, fps: null, resolutionBubble: resolution })
        : this.setState({ resolution: null, fps: null, resolutionBubbble: null })} className={this.state.resolution == resolutionP
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      {resolution}
    </div>)
  }

  renderOptionFPS(fps) {
    return (<div onClick={(
    ) => this.state.fps != fps
        ? this.setState({ fps: fps })
        : this.setState({ fps: null })} className={this.state.fps == fps
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{fps + ' fps'}</h4>
    </div>)
  }

  renderStorageOption(storage) {
    return (<div onClick={(
    ) => this.state.storage != storage
        ? this.setState({ storage: storage })
        : this.setState({ storage: null })} className={this.state.storage == storage
          ? 'optionClicked'
          : 'option'}>
      {
        storage >= 1000
          ? storage / 1000 + ' TB'
          : storage != 0
            ? storage + ' GB'
            : 'No onboard storage'
      }

    </div>)
  }

  renderQualityOption(quality) {
    return (<div onClick={(
    ) => this.state.quality != quality
        ? this.setState({ quality: quality })
        : this.setState({ quality: null })} className={this.state.quality == quality
          ? 'optionClicked'
          : 'option'}>
      {quality}

    </div>)
  }

  renderEstimatedRetentionTime() {
    var retention = null;
    try {
      if (this.state.resolution != null && this.state.quality != null && this.state.storage != null) {
        const resolution = 'res' + this.state.resolution;
        const storage = 'storage' + this.state.storage;

        retention = retentionTime[storage][resolution][this.state.quality] + ' days';
      } else {
        retention = 'Please select resolution and quality settings.';
      }
      return retention;
    } catch (error) {
      return 'Selected quality not supported on selected resolution.';
    }
  }

  renderOptionIR() {
    return (<div>
      <div onClick={(
      ) => this.state.ir != true
          ? this.setState({ ir: true })
          : this.setState({ ir: null })} className={this.state.ir == true
            ? 'optionClicked'
            : 'option'}>
        IR Illumination
      </div>
    </div>)
  }

  renderOptionOpticZoom() {
    return (<div>
      <div onClick={(
      ) => this.state.opticZoom != true
          ? this.setState({ opticZoom: true })
          : this.setState({ opticZoom: null })} className={this.state.opticZoom == true
            ? 'optionClicked'
            : 'option'}>
        Varifocal Lens
      </div>
    </div>)
  }

  renderOptionPeopleDetection() {
    return (<div>
      <div onClick={(
      ) => this.state.peopleDetection != true
          ? this.setState({ peopleDetection: true })
          : this.setState({ peopleDetection: null })} className={this.state.peopleDetection == true
            ? 'optionClicked'
            : 'option'}>
        People Detection
      </div>
    </div>)
  }

  renderOptionAdvancedPeopleCounter() {
    return (<div>
      <div onClick={(
      ) => this.state.advancedPeopleCounter != true
          ? this.setState({ advancedPeopleCounter: true })
          : this.setState({ advancedPeopleCounter: null })} className={this.state.advancedPeopleCounter == true
            ? 'optionClicked'
            : 'option'}>
        Advanced People Counter
      </div>
    </div>)
  }

  renderOptionVehicleDetection() {
    return (<div>
      <div onClick={(
      ) => this.state.vehicleDetection != true
          ? this.setState({ vehicleDetection: true })
          : this.setState({ vehicleDetection: null })} className={this.state.vehicleDetection == true
            ? 'optionClicked'
            : 'option'}>
        Vehicle Detection
      </div>
    </div>)
  }

  renderOptionAttributeSearch() {
    return (<div>
      <div onClick={(
      ) => this.state.attributeSearch != true
          ? this.setState({ attributeSearch: true })
          : this.setState({ attributeSearch: null })} className={this.state.attributeSearch == true
            ? 'optionClicked'
            : 'option'}>
        Attribute Search
      </div>
    </div>)
  }

  renderSecondarySenLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.mvSenseLicenseTerm != years
        ? this.setState({ mvSenseLicenseTerm: years })
        : this.setState({ mvSenseLicenseTerm: null })} className={this.state.mvSenseLicenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{years}
      </h4>
    </div>)
  }

  renderOptionSenYes() {
    return (<div>
      <div onClick={(
      ) => this.state.mvSense != true
          ? this.setState({ mvSense: true })
          : this.setState({ mvSense: null })} className={this.state.mvSense == true
            ? 'optionClicked'
            : 'option'}>
        Yes
      </div>
      {
        this.state.mvSense == true
          ? <div className="secondaryOptionsContainer">
            <h4>Please select the duration for your MV Sense License (Years)</h4>
            <h5>MV Sense allows users to further utilize the MV machine-learning based computer vision outputs via API to create custom business solutions.</h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondarySenLicenseOptions(1)}
              {this.renderSecondarySenLicenseOptions(3)}
              {this.renderSecondarySenLicenseOptions(5)}
              {this.renderSecondarySenLicenseOptions(7)}
              {this.renderSecondarySenLicenseOptions(10)}
            </div>
          </div>
          : <div></div>
      }
    </div>)
  }

  renderSecondaryDisplayLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.mvDisplayLicenseTerm != years
        ? this.setState({ mvDisplayLicenseTerm: years })
        : this.setState({ mvDisplayLicenseTerm: null })} className={this.state.mvDisplayLicenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{years}
      </h4>
    </div>)
  }

  renderOptionDisplayYes() {
    return (<div>
      <div onClick={(
      ) => this.state.mvDisplay != true
          ? this.setState({ mvDisplay: true })
          : this.setState({ mvDisplay: null })} className={this.state.mvDisplay == true
            ? 'optionClicked'
            : 'option'}>
        Yes
      </div>
      {
        this.state.mvDisplay == true
          ? <div className="secondaryOptionsContainer">
            <h4>Please select the duration for your Meraki Display License (Years)</h4>
            <h5>Meraki Display is an Apple TV app capable of streaming MV cameras on a display for video wall monitoring.</h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryDisplayLicenseOptions(1)}
              {this.renderSecondaryDisplayLicenseOptions(3)}
              {this.renderSecondaryDisplayLicenseOptions(5)}
              {this.renderSecondaryDisplayLicenseOptions(7)}
              {this.renderSecondaryDisplayLicenseOptions(10)}
            </div>
          </div>
          : <div></div>
      }
    </div>)
  }

  renderOptionDisplayNo() {
    return (<div>
      <div onClick={(
      ) => this.state.mvDisplay != false
          ? this.setState({ mvDisplay: false, mvDisplayLicenseTerm: null })
          : this.setState({ mvDisplay: null })} className={this.state.mvDisplay == false
            ? 'optionClicked'
            : 'option'}>
        No
      </div>
    </div>)
  }

  renderOptionSenNo() {
    return (<div>
      <div onClick={(
      ) => this.state.mvSense != false
          ? this.setState({ mvSense: false, mvSenseLicenseTerm: null })
          : this.setState({ mvSense: null })} className={this.state.mvSense == false
            ? 'optionClicked'
            : 'option'}>
        No
      </div>
    </div>)
  }

  renderOptionCAYes() {
    return (<div>
      <div onClick={(
      ) => this.state.mvCA != true
          ? this.setState({ mvCA: true })
          : this.setState({ mvCA: null })} className={this.state.mvCA == true
            ? 'optionClicked'
            : 'option'}>
        Yes
      </div>
      {
        this.state.mvCA == true
          ? <div className="secondaryOptionsContainer">
            <h4>How many days of Cloud Archive do you need?</h4>
            <h5>Cloud archive options for MV cameras allow for 30, 90, 180 or 365 days of continuous cloud backup recording.</h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryCADurationOptions(30)}
              {this.renderSecondaryCADurationOptions(90)}
              {this.renderSecondaryCADurationOptions(180)}
              {this.renderSecondaryCADurationOptions(365)}
            </div>
            {
              this.state.mvCADuration != null
                ? <div>
                  <h4>
                    <br></br>Please select the duration for your Cloud Archive License (Years)</h4>
                  <div className="secondaryOptionsContainer2">
                    {this.renderSecondaryCALicenseOptions(1)}
                    {this.renderSecondaryCALicenseOptions(3)}
                    {this.renderSecondaryCALicenseOptions(5)}
                    {this.renderSecondaryCALicenseOptions(7)}
                    {this.renderSecondaryCALicenseOptions(10)}
                  </div>
                </div>
                : null
            }
          </div>
          : null
      }
    </div>)
  }

  renderOptionCANo() {
    return (<div>
      <div onClick={(
      ) => this.state.mvCA != false
          ? this.setState({ mvCA: false, mvCADuration: null, cloudArchiveLicenseTerm: null })
          : this.setState({ mvCA: null })} className={this.state.mvCA == false
            ? 'optionClicked'
            : 'option'}>
        No
      </div>
    </div>)
  }

  renderSecondaryCADurationOptions(days) {
    return (<div onClick={(
    ) => this.state.mvCADuration != days
        ? this.setState({ mvCADuration: days })
        : this.setState({ mvCADuration: null })} className={this.state.mvCADuration == days
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{days}
      </h4>
    </div>)
  }

  renderSecondaryCALicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.cloudArchiveLicenseTerm != years
        ? this.setState({ cloudArchiveLicenseTerm: years })
        : this.setState({ cloudArchiveLicenseTerm: null })} className={this.state.cloudArchiveLicenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{years}
      </h4>
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ RENDER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  render() {
    return (<div>

      <div className="form">

        <div className='leftForm'>
          {this.optionBubbleLogic()}
          {this.devicePickingLogic()}
        </div>

        <div className='rightFormContainer'>
          <div className='rightForm'>
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki IoT</h6>
              <h1>MV Smart Cameras</h1>
              <h3 className='bottomSubHeader'>
                The MV family eliminates the complex and costly hardware required by traditional solutions, removing the limitations placed on surveillance deployments.
              </h3>
            </div>

            <div>
              <h2>Choose your license duration in years.
              </h2>
              <div className="secondaryOptionsContainer2">
                {this.renderSecondaryLicenseOptions(1)}
                {this.renderSecondaryLicenseOptions(3)}
                {this.renderSecondaryLicenseOptions(5)}
                {this.renderSecondaryLicenseOptions(7)}
                {this.renderSecondaryLicenseOptions(10)}
              </div>
            </div>

            <div>
              <h2>Choose your deployment environment.</h2>
              {this.renderOptionIndoor()}
              {this.renderOptionOutdoor()}
            </div>

            <div>
              <h2>Choose your Smart Retention video quality.</h2>
              <a href="https://documentation.meraki.com/MV/Initial_Configuration/Smart_Retention" target="_blank">Learn more about Smart Retention</a>
              {this.renderSmartQualityOption('Standard', '1080p / 24fps')}
              {this.renderSmartQualityOption('Enhanced', '4MP / 24fps')}
              {this.renderSmartQualityOption('Ultra', '4K / 15fps')}
            </div>

            <div>
              <h2>How many Smart Retention days do you require.</h2>
              <a href="https://documentation.meraki.com/MV/Initial_Configuration/Video_Retention" target="_blank">Learn more about video retention</a>
              {this.renderSmartRetention()}
            </div>

            <div>
              <h2>Choose your minimum storage capactiy.</h2>
              {this.renderStorageOption('128')}
              {this.renderStorageOption('256')}
              {this.renderStorageOption('512')}
              {this.renderStorageOption('1000')}
            </div>

            <div>
              <h2>Choose your minimum video resolution.</h2>
              <div className='secondaryOptionsContainer2'>
                {this.renderOptionResolution('1080p', 1080)}
                {this.renderOptionResolution('4MP', 1520)}
                {this.renderOptionResolution('4K', 2160)}
              </div>
              {/*
              <div className='secondaryOptionsContainer2'>
                {this.renderOptionResolution('1080x1080')}
                {this.renderOptionResolution('2058x2058')}
              </div>
              <div className='secondaryOptionsContainer2'>
                {this.renderOptionResolution('2112x2112')}
                {this.renderOptionResolution('2880x2880')}
              </div>
              */
              }
            </div>
            {/*
            <div>
              <h2>Choose your quality.</h2>
              {this.renderQualityOption('Standard')}
              {this.renderQualityOption('Enhanced')}
              <div className={this.state.resolution == "720p" || this.state.resolution == "4MP" || this.state.resolution == "1080x1080"
                  ? "disabled"
                  : ""}>
                {this.renderQualityOption('High')}
              </div>
            </div>
*/
            }

            <div>
              <h2>Choose your features.</h2>
              {this.renderOptionIR()}
              {this.renderOptionOpticZoom()}
              {this.renderOptionPeopleDetection()}
              {this.renderOptionAdvancedPeopleCounter()}
              {this.renderOptionVehicleDetection()}
              {this.renderOptionAttributeSearch()}
            </div>

            <div>
              <h2>Do you need MV Cloud Archive?</h2>
              <a href="https://documentation.meraki.com/MV/Initial_Configuration/MV_Cloud_Archive" target="_blank">Learn more about Cloud Archive</a>
              {this.renderOptionCAYes()}
              {this.renderOptionCANo()}
            </div>

            <div>
              <h2>Do you need MV sense?</h2>
              <a href="https://meraki.cisco.com/wp-content/uploads/2020/05/meraki_datasheet_mv_sense.pdf" target="_blank">Learn more about MV Sense</a>
              {this.renderOptionSenYes()}
              {this.renderOptionSenNo()}
            </div>

            <div>
              <h2> Do you need Meraki Display?</h2>
              <a href="https://documentation.meraki.com/MV/Viewing_Video/Meraki_Display_Introduction" target="_blank">Learn more about video wall monitoring through Meraki Display</a>
              {this.renderOptionDisplayYes()}
              {this.renderOptionDisplayNo()}
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

// ========================================

export default MVPicker;
