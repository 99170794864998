import React from "react";

import { NavLink } from "react-router-dom";

var isDark = document.documentElement.classList.contains("dark");

var e = document.documentElement;

var observer = new MutationObserver(function (event) {

  isDark = document.documentElement.classList.contains("dark");
  console.log(isDark);
})

observer.observe(e, {
  attributes: true,
  attributeFilter: ['class'],
  childList: false,
  characterData: false
})

const home = () => {
  console.log('does class contain dark?' + document.documentElement.classList.contains("dark"))
  return (
    <div className="homeForm">
      <div>
        <div className="mainHomeTitle">
          <h1>Meraki Sizing Tool</h1>
          <h3>Select a solution to size</h3>
        </div>

        <div className="homeIconsContainer">

          <div className="homeIconsContainerSplit">
            <NavLink to="/mr">
              <div className="optionHome">
                <div className="leftSide">
                  <p className="h3Home">MR</p>
                  <p className="pHome">Wireless</p>
                </div>
                <div className="rightSide">
                  <img className="homeIcons" src="/img/icons/mr.png"></img>
                </div>
              </div>
            </NavLink>

            <NavLink to="/ms">
              <div className="optionHome">
                <div className="leftSide">
                  <p className="h3Home">MS</p>
                  <p className="pHome">Switching</p>
                </div>
                <div className="rightSide">
                  <img className="homeIcons" src="/img/icons/ms.png"></img>
                </div>
              </div>
            </NavLink>

            <NavLink to="/mx">
              <div className="optionHome">
                <div className="leftSide">
                  <p className="h3Home">MX</p>
                  <p className="pHome">Security</p>
                </div>
                <div className="rightSide">
                  <img className="homeIcons" src="/img/icons/mx.png"></img>
                </div>
              </div>
            </NavLink>

            

            {/* <div className="homeIconsContainer2">
              <NavLink to="/mr">
                <img className="homeIconsMR" />
                <h3 className="homeNavText">MR</h3>
              </NavLink>
            </div>

            <div className="homeIconsContainer2">
              <NavLink to="/ms">
                <img className="homeIconsMS" />
                <h3 className="homeNavText">MS</h3>
              </NavLink>
            </div>

            <div className="homeIconsContainer2">
              <NavLink to="/mx">
                <img className="homeIconsMX" />
                <h3 className="homeNavText">MX</h3>
              </NavLink>
            </div>*/}
          </div>
          
            <div className="homeIconsContainerSplit">

            <NavLink to="/mg">
            <div className="optionHome">
              <div className="leftSide">
                <p className="h3Home">MG</p>
                <p className="pHome">Gateways</p>
              </div>
              <div className="rightSide">
                <img className="homeIconsSensor" src="/img/icons/mg.png"></img>
              </div>
            </div>
            </NavLink>

            <NavLink to="/mv">
              <div className="optionHome">
                <div className="leftSide">
                  <p className="h3Home">MV</p>
                  <p className="pHome">Cameras</p>
                </div>
                <div className="rightSide">
                  <img className="homeIcons" src="/img/icons/mv.png"></img>
                </div>
              </div>
          </NavLink>

          <NavLink to="/mt">
            <div className="optionHome">
              <div className="leftSide">
                <p className="h3Home">MT</p>
                <p className="pHome">Sensors</p>
              </div>
              <div className="rightSide">
                <img className="homeIconsSensor" src="/img/icons/mt.png"></img>
              </div>

            </div>
            </NavLink>

            
        </div>

        {/* <div className="homeIconsContainerSplit">
            <div className="homeIconsContainer2">
              <NavLink to="/mi">
                <img className="homeIconsMI"/>
                <h3 className="homeNavText">MI</h3>
              </NavLink>
            </div>

            <div className="homeIconsContainer2">
              <NavLink to="/mv">
                <img className="homeIconsMV" />
                <h3 className="homeNavText">MV</h3>
              </NavLink>
            </div>

            <div className="homeIconsContainer2">
              <NavLink to="/mt">
                <img className="homeIconsMT" />
                <h3 className="homeNavText">MT</h3>
              </NavLink>
            </div>
          </div> */}
      </div>
    </div>
    </div >
  );
};

export default home;
