//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';

var devices = [
  {
    model: 'MT10',
    environment: 'it',
    type: 'temperature'
  }, {
    model: 'MT11',
    environment: 'cold',
    type: 'temperature'
  }, {
    model: 'MT12',
    environment: 'it',
    type: 'water'
  }, {
    model: 'MT14',
    environment: 'it',
    type: 'temperature',
    type2: 'air'
  }, {
    model: 'MT15',
    environment: 'it',
    type: 'temperature',
    type2: 'air'
  }, {
    model: 'MT20',
    environment: 'it',
    type: 'door'
  }, {
    model: 'MT30',
    environment: 'it',
    type: 'button'
  }, {
    model: 'MT40',
    environment: 'it',
    type: 'power'
  }
]

const metalProbe = {
  model: 'Bare Metal Probe',
  sku: 'MA-CBL-TEMP-ME-1',
  description: '10’ bare metal temperature probe sensor.'
}

const glycolProbe = {
  model: 'Glycol-Encased Probe',
  sku: 'MA-CBL-TEMP-GL-1',
  description: '10’ glycol-encased temperature probe sensor.'
}

const acPower = {
  model: 'AC Power Adapter',
  sku: 'MA-PWR-USB-xx (xx = US, EU, UK or AU)',
  description: 'Includes a power adapter and a 3m USB-C cable.'
}

const ethPower = {
  model: 'Ethernet Adapter',
  sku: 'MA-PWR-ETH',
  description: 'Includes an ethernet dongle and a 1m USB-C cable.'
}

const onBoardEth = {
  model: 'Onboard Ethernet',
  sku: null,
  description: 'Ethernet port is available on the device for power only.'
}

const Battery = {
  model: 'AA Battery',
  sku: '',
  description: 'All MT products will come with AA batteries (Alkaline).',
  description2: 'AC or Ethernet Power Adapter required for PM2.5 support.'
}

const leakCable = {
  model: 'Water Leak Detection Cable',
  sku: 'MA-CBL-LEAK-1',
  description: 'An 8 ft (2.4 m) long leak detection cable that is water sensitive across the length of the cable.'
}

const leakCradle = {
  model: 'Spot Leak Detection Cradle',
  sku: 'MA-CBL-LEAK-2',
  description: 'An enclosure that detects water leaks from a specific location.'
}

const c14c15 = {
  model: 'C14 - C15 Connector',
  sku: 'MA-PWR-C14-C15-1',
  description: 'Provides output power from MT40 to a device with a compatible IEC C13 or C15 power input.'
}

const powerCord = {
  model: 'Power Cord',
  sku: 'MA-PWR-CORD-XX (Country specific)',
  description: 'IEC C14 input cable.'
}

//--------------------------------- State Variables ---------------------------------

class MTPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      type2: null,
      environment: null,

      clickedDeviceModel: null,
      deviceModel: null,
      license: null,
      licenseTerm: null,

      clickedAccessory: null,
      clickedAccessorySku: null,
      clickedAccessorySummary: null,

      clickedPower: null,
      clickedPowerSku: null,
      clickedPowerSummary: null
    };
  }

  //--------------------------------- Devices ---------------------------------
  renderDeviceInfo(header, deviceModels) {
    if (header != null && deviceModels != null) {
      return (
        <p>
          <h6 className='switchInfoHeader'>{header + ': '}</h6>
          {deviceModels}
        </p>
      );
    } else {
      return null;
    }
  }

  renderAccessoryInfo(model, sku, summary) {
    if (this.state.clickedAccessory !== model) {
      return (
        <div
          onClick={() => this.setState({ clickedAccessory: model, clickedAccessorySku: sku, clickedAccessorySummary: summary })}
          className='antennaPicsCircle'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => this.setState({ clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null })}
          className='antennaPicsCircleSelected'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    }
  }

  renderPowerInfo(model, sku, summary) {
    if (this.state.clickedPower !== model) {
      return (
        <div
          onClick={() => this.setState({ clickedPower: model, clickedPowerSku: sku, clickedPowerSummary: summary })}
          className='antennaPicsCircle'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => this.setState({ clickedPower: null, clickedPowerSku: null, clickedPowerSummary: null })}
          className='antennaPicsCircleSelected'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    }
  }

  renderLicenseSKU() {
    if (this.state.licenseTerm != null) {
      return <p>{'1 x LIC-MT-' + this.state.licenseTerm + 'Y'}</p>;
    } else {
      return <p>{'1 x LIC-MT-xY (x = 1, 3, 5, 7, 10 years)'}</p>;
    }
  }

  renderAccessorySKU() {
    if (this.state.clickedAccessorySku != null) {
      return <p>{this.state.clickedAccessorySku}</p>;
    } else {
      return null;
    }
  }

  renderClickedPowerSku() {
    if (this.state.clickedPowerSku != null) {
      return <p>{this.state.clickedPowerSku}</p>;
    } else {
      return null;
    }
  }

  renderDeviceModel(model) {
    return (<div className={model + ' switchPictureContainer'}>
      <div onClick={this.state.deviceModel != model
        ? () => this.setState({ deviceModel: model, clickedDeviceModel: null, clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null, clickedPower: null, clickedPowerSku: null, clickedPowerSummary: null })
        : () => this.setState({ deviceModel: null })} className={'deviceModel' + model}>
        <h6 className={this.state.deviceModel == model
          ? 'selectedSwitchHeader'
          : ''}>{model}</h6>

        {
          model == "MT15"
            ? (<img src={'/img/mt/' + model + '.png'} alt={model} className='modelIconSmall modelIcon' />)
            : (<img src={'/img/mt/' + model + '.png'} alt={model} className='modelIconXSmall modelIcon' />)
        }

      </div>

      <div className={'switchInfo'}>
        {
          this.state.deviceModel == 'MT10' && model == this.state.deviceModel
            ? <div>
              <div className='switchInfoSelected'>
                {this.renderDeviceInfo('Type', 'Temperature & Humidity Sensor')}
                {this.renderDeviceInfo('Temperature Range', '0°C – 55°C (32F° to 131F°)')}
                {this.renderDeviceInfo('Storage', '5 Days')}
                {this.renderDeviceInfo('Battery', '5 Years')}

                {this.renderDeviceInfo('Please select a power option', '')}
                <div className='antennaPicsContainerContainer'>
                  {this.renderPowerInfo(Battery.model, '' + Battery.sku, Battery.description)}
                  {this.renderPowerInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                  {this.renderPowerInfo(ethPower.model, '1 x ' + ethPower.sku, ethPower.description)}
                </div>
                <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                <p>1 x MT10-HW</p>
                {this.renderLicenseSKU()}
                {this.renderClickedPowerSku()}
              </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mt10-datasheet/?file">Datasheet</a>
                <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT10_Installation_Guide_-_Temperature_and_Humidity">Installation</a>
              </div>
            </div>
            : this.state.deviceModel == 'MT11' && model == this.state.deviceModel
              ? <div>
                <div className='switchInfoSelected'>
                  {this.renderDeviceInfo('Type', 'Temperature Probe Sensor')}
                  {this.renderDeviceInfo('Temperature Range', '-40°C – 55°C (-40°F to 131°F)')}
                  {this.renderDeviceInfo('Storage', '5 Days')}
                  {this.renderDeviceInfo('Battery', '5 Years')}

                  {this.renderDeviceInfo('Please select a probe option', '')}
                  <div className='antennaPicsContainerContainer'>
                    {this.renderAccessoryInfo(metalProbe.model, '1 x ' + metalProbe.sku, metalProbe.description)}
                    {this.renderAccessoryInfo(glycolProbe.model, '1 x ' + glycolProbe.sku, glycolProbe.description)}
                  </div>
                  <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                  {this.renderDeviceInfo('Please select a power option', '')}
                  <div className='antennaPicsContainerContainer'>
                    {this.renderPowerInfo(Battery.model, '' + Battery.sku, Battery.description)}
                    {this.renderPowerInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                    {this.renderPowerInfo(ethPower.model, '1 x ' + ethPower.sku, ethPower.description)}
                  </div>
                  <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                  <p>1 x MT11-HW</p>
                  {this.renderLicenseSKU()}
                  {this.renderAccessorySKU()}
                  {this.renderClickedPowerSku()}
                </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT11_Datasheet_-_Probe_Sensor">Datasheet</a>
                  <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT11_Probe_Sensor_Installation_Guide">Installation</a>
                </div>
              </div>
              : this.state.deviceModel == 'MT12' && model == this.state.deviceModel
                ? <div>
                  <div className='switchInfoSelected'>
                    {this.renderDeviceInfo('Type', 'Water Leak Detection Sensor')}
                    {this.renderDeviceInfo('Storage', '5 Days')}
                    {this.renderDeviceInfo('Battery', '5 Years')}

                    {this.renderDeviceInfo('Please select a detection accessory', '')}
                    <div className='antennaPicsContainerContainer'>
                      {this.renderAccessoryInfo(leakCable.model, '1 x ' + leakCable.sku, leakCable.description)}
                      {this.renderAccessoryInfo(leakCradle.model, '1 x ' + leakCradle.sku, leakCradle.description)}
                    </div>
                    <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                    {this.renderDeviceInfo('Please select a power option', '')}
                    <div className='antennaPicsContainerContainer'>
                      {this.renderPowerInfo(Battery.model, '' + Battery.sku, Battery.description)}
                      {this.renderPowerInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                      {this.renderPowerInfo(ethPower.model, '1 x ' + ethPower.sku, ethPower.description)}
                    </div>
                    <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                    <p>1 x MT12-HW</p>
                    {this.renderLicenseSKU()}
                    {this.renderAccessorySKU()}
                    {this.renderClickedPowerSku()}
                  </div>
                  <div className='datasheetContainer'>
                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT12_Datasheet_-_Water_Detection">Datasheet</a>
                    <p className='datasheetDivider'> | </p>
                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT12_Installation_Guide_-_Water_Detection">Installation</a>
                  </div>
                </div>

                : this.state.deviceModel == 'MT20' && model == this.state.deviceModel
                  ? <div>
                    <div className='switchInfoSelected'>
                      {this.renderDeviceInfo('Type', 'Open/Close Sensor')}
                      {this.renderDeviceInfo('Storage', '5 Days')}
                      {this.renderDeviceInfo('Battery', '5 Years')}

                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                      <p>1 x MT20-HW</p>
                      {this.renderLicenseSKU()}
                    </div>
                    <div className='datasheetContainer'>
                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT20_Datasheet_-_Open%2F%2FClose_Detection">Datasheet</a>
                      <p className='datasheetDivider'> | </p>
                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT20_Installation_Guide_-_Open%2F%2FClose_Detection">Installation</a>
                    </div>
                  </div>

                  : this.state.deviceModel == 'MT14' && model == this.state.deviceModel
                    ? <div>
                      <div className='switchInfoSelected'>
                        {this.renderDeviceInfo('Type', 'Air Quality')}
                        {this.renderDeviceInfo('Temperature Range', '0°C to 55°C (32F° to 131F°)')}
                        {this.renderDeviceInfo('Humidity Range', '0% to 95% Relative Humidity')}
                        {this.renderDeviceInfo('TVOC Range', 'Up to 10,000 µg/m³')}
                        {this.renderDeviceInfo('PM2.5 Range', 'Up to 1,000 µg/m³')}
                        {this.renderDeviceInfo('Ambient Noise Range', '20 to 120 dBA')}
                        {this.renderDeviceInfo('Storage', '5 Days')}
                        {this.renderDeviceInfo('Battery', '2 Years')}

                        {this.renderDeviceInfo('Please select a power option', '')}
                        <div className='antennaPicsContainerContainer'>
                          {this.renderPowerInfo('AA Battery', '' + Battery.sku, Battery.description2)}
                          {this.renderPowerInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                          {this.renderPowerInfo(ethPower.model, '1 x ' + ethPower.sku, ethPower.description)}
                        </div>
                        <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                        <p>1 x MT14-HW</p>
                        {this.renderLicenseSKU()}
                        {this.renderClickedPowerSku()}
                      </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT14_Datasheet_-_Indoor_Air_Quality_Monitor">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT14_Installation_Guide_-_Indoor_Air_Quality">Installation</a>
                      </div>
                    </div>

                    : this.state.deviceModel == 'MT15' && model == this.state.deviceModel
                      ? <div>
                        <div className='switchInfoSelected'>
                          {this.renderDeviceInfo('Type', 'Air Quality')}
                          {this.renderDeviceInfo('Temperature Range', '0°C to 55°C (32F° to 131F°)')}
                          {this.renderDeviceInfo('Humidity Range', '0% to 95% Relative Humidity')}
                          {this.renderDeviceInfo('CO2', '400ppm to 5,000ppm')}
                          {this.renderDeviceInfo('TVOC Range', '160 to 10,000 µg/m³')}
                          {this.renderDeviceInfo('PM2.5 Range', '0 to 1,000 µg/m³')}
                          {this.renderDeviceInfo('Ambient Noise Range', '20 to 120 dBA')}

                          {this.renderDeviceInfo('Please select a power option', '')}
                          <div className='antennaPicsContainerContainer'>
                            {this.renderPowerInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                            {this.renderPowerInfo(onBoardEth.model, null, onBoardEth.description)}
                          </div>
                          <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                          <p>1 x MT15-HW</p>
                          {this.renderLicenseSKU()}
                          {this.renderClickedPowerSku()}
                        </div>
                        <div className='datasheetContainer'>
                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT15_Datasheet_-_Indoor_Air_Quality_w%2F%2FCO2">Datasheet</a>
                          <p className='datasheetDivider'> | </p>
                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT15_Installation_Guide_-_Indoor_Air_Quality_w%2F%2FCO2">Installation</a>
                        </div>
                      </div>

                      : this.state.deviceModel == 'MT30' && model == this.state.deviceModel
                        ? <div>
                          <div className='switchInfoSelected'>
                            {this.renderDeviceInfo('Type', 'Automation Button')}
                            {this.renderDeviceInfo('Storage', '5 Days')}
                            {this.renderDeviceInfo('Battery', '5 Years')}

                            {this.renderDeviceInfo('Please select a power option', '')}
                            <div className='antennaPicsContainerContainer'>
                              {this.renderPowerInfo(Battery.model, '' + Battery.sku, Battery.description)}
                              {this.renderPowerInfo(acPower.model, '1 x ' + acPower.sku, acPower.description)}
                              {this.renderPowerInfo(ethPower.model, '1 x ' + ethPower.sku, ethPower.description)}
                            </div>
                            <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                            <p>1 x MT30-HW</p>
                            {this.renderLicenseSKU()}
                            {this.renderClickedPowerSku()}
                          </div>
                          <div className='datasheetContainer'>
                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT30_Datasheet_-_Smart_Automation_Button">Datasheet</a>
                            <p className='datasheetDivider'> | </p>
                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT30_Installation_Guide_-_Smart_Automation_Button">Installation</a>
                          </div>
                        </div>

                        : this.state.deviceModel == 'MT40' && model == this.state.deviceModel
                          ? <div>
                            <div className='switchInfoSelected'>
                              {this.renderDeviceInfo('Type', 'Smart Power Controller')}
                              {this.renderDeviceInfo('RMS Voltage Range', '250V')}
                              {this.renderDeviceInfo('RMS Current Range 1', '100 - 120VAC, 50 - 60Hz, 12A max')}
                              {this.renderDeviceInfo('RMS Current Range 2', '200 - 240VAC, 50 - 60Hz, 10A max')}
                              {this.renderDeviceInfo('Real Power Range', '0 - 2400W')}
                              {this.renderDeviceInfo('Apparent Power Range', '0 - 250V')}
                              {this.renderDeviceInfo('Power Factor Range', '0 - 100%')}
                              {this.renderDeviceInfo('Energy Range', '0 - 144kWh')}

                              {this.renderDeviceInfo('Please select output power cable', '')}
                              <div className='antennaPicsContainerContainer'>
                                {this.renderAccessoryInfo(c14c15.model, '1 x ' + c14c15.sku, c14c15.description)}
                              </div>
                              <p className='accessorySummary'>{this.state.clickedAccessorySummary}</p>

                              {this.renderDeviceInfo('Please select input power cable', '')}
                              <div className='antennaPicsContainerContainer'>
                                {this.renderPowerInfo(powerCord.model, '' + powerCord.sku, powerCord.description)}
                              </div>
                              <p className='accessorySummary'>{this.state.clickedPowerSummary}</p>

                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                              <p>1 x MT40-HW</p>
                              {this.renderLicenseSKU()}
                              {this.renderAccessorySKU()}
                              {this.renderClickedPowerSku()}
                            </div>
                            <div className='datasheetContainer'>
                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Datasheets/MT40_Datasheet_-_Smart_Power_Controller">Datasheet</a>
                              <p className='datasheetDivider'> | </p>
                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MT/MT_Installation_Guides/MT40_Installation_Guide_-_Smart_Power_Controller">Installation</a>
                            </div>
                          </div>

                          : ('')
        }
      </div>
    </div >)
  }

  //--------------------------------- Picking Logic ---------------------------------

  devicePickingLogic() {
    return (<div>
      {
        (<div>
          {
            devices.map(
              deviceModel => this.state.type != null && deviceModel.type != this.state.type && deviceModel.type2 != this.state.type
                ? ''
                : this.state.environment != null && deviceModel.environment != this.state.environment
                  ? ''
                  : this.renderDeviceModel(deviceModel.model))
          }
        </div>)
      }
    </div>)
  }

  //--------------------------------- Bubbles ---------------------------------

  optionBubbleLogic() {
    return (<div>
      {
        this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ licenseTerm: null })}>{this.state.licenseTerm + ' Year License'}</div>
          : null
      }
      {
        this.state.type == 'temperature'
          ? <div className='optionBubble' onClick={() => this.setState({ type: null })}>Type: Temperature & Humidity</div>
          : this.state.type == 'water'
            ? <div className='optionBubble' onClick={() => this.setState({ type: null })}>Type: Water Leaks</div>
            : this.state.type == 'door'
              ? <div className='optionBubble' onClick={() => this.setState({ type: null })}>Type: Access & Entry</div>
              : this.state.type == 'power'
                ? <div className='optionBubble' onClick={() => this.setState({ type: null })}>Type: Power Controller</div>
                : null
      }
      {
        this.state.environment == 'it'
          ? <div className='optionBubble' onClick={() => this.setState({ environment: null })}>IT Space</div>
          : this.state.environment == 'cold'
            ? <div className='optionBubble' onClick={() => this.setState({ environment: null })}>Cold Storage</div>
            : null
      }

    </div>)
  }

  //--------------------------------- Options ---------------------------------

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })} className={this.state.licenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      {years}
    </div>)
  }

  renderOptionType(description, type) {
    return (<div onClick={(
    ) => this.state.type != type
        ? this.setState({ type: type })
        : this.setState({ type: null })} className={this.state.type == type
          ? 'optionClicked'
          : 'option'}>
      {description}
    </div>)
  }

  renderOptionEnvironment(description, enviro) {
    return (<div onClick={(
    ) => this.state.environment != enviro
        ? this.setState({ environment: enviro })
        : this.setState({ environment: null })} className={this.state.environment == enviro
          ? 'optionClicked'
          : 'option'}>
      {description}
    </div>)
  }

  //--------------------------------- Render ---------------------------------
  render() {
    return (<div>

      <div className="form">

        <div className='leftForm'>
          {this.optionBubbleLogic()}
          {this.devicePickingLogic()}
        </div>

        <div className='rightFormContainer'>
          <div className='rightForm'>
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki IoT</h6>
              <h1>MT Sensors</h1>
              <h3 className='bottomSubHeader'>
                The MT Cloud managed sensors are exceptionally simple to configure and deploy.
              </h3>
              <a href="https://documentation.meraki.com/MT/MT_General_Articles/MT_Frequently_Asked_Questions#Connectivity" target="_blank">Learn more about MT Gateways</a>
            </div>

            <div>
              <h2>Choose your license duration in years.
              </h2>
              <div className="secondaryOptionsContainer2">
                {this.renderSecondaryLicenseOptions(1)}
                {this.renderSecondaryLicenseOptions(3)}
                {this.renderSecondaryLicenseOptions(5)}
                {this.renderSecondaryLicenseOptions(7)}
                {this.renderSecondaryLicenseOptions(10)}
              </div>
            </div>

            <div>
              <h2>Choose your deployment environment.</h2>
              {this.renderOptionEnvironment('IT Space', 'it')}
              {this.renderOptionEnvironment('Cold Storage', 'cold')}
            </div>

            <div>
              <h2>Choose your sensor type.</h2>
              {this.renderOptionType('Temperature & Humidity', 'temperature')}
              {this.renderOptionType('Water Leaks', 'water')}
              {this.renderOptionType('Access & Entry', 'door')}
              {this.renderOptionType('Air Quality', 'air')}
              {this.renderOptionType('Automation Button', 'button')}
              {this.renderOptionType('Power Controller', 'power')}
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

// ========================================

export default MTPicker;
