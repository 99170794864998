//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';

var devices = [
  {
    model: 'XSmall',
    size: 'xs'
  }, {
    model: 'Small',
    size: 's'
  }, {
    model: 'Medium',
    size: 'm'
  }, {
    model: 'Large',
    size: 'l'
  }, {
    model: 'XLarge',
    size: 'xl'
  }
]

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ STATE VARIABLES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

class MIPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedSwitchModel: null,
      clickedSwitchModelDetails: null,
      switchModel: null,
      outdoor: null,
      storage: null,
      opticZoom: null,
      ir: null,

      cloudArchive: null,
      cloudArchiveDuration: null,
      cloudArchiveLicenseTerm: null,

      license: null,
      licenseTerm: null,

      mxModel: null,
      miSize: null
    };
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SWITCHES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderSwitchInfo(header, switchModels) {
    return (<div>
      <p>
        <h6 className='switchInfoHeader'>{header + ': '}</h6>
        {switchModels}</p>
    </div>)
  }

  renderSwitchModelDetailsClicked() {
    return (<div>
      {
        this.state.clickedSwitchModel != null
          ? <div className='secondaryOptionsContainer secondaryOptionsContainerModel'>
            <h4>{this.state.clickedSwitchModel}</h4>
            <h5 className="commaToNewLine">{this.state.clickedSwitchModelDetails.replace(/~/g, "\n")}</h5>
          </div>
          : ""
      }
    </div>)
  }

  renderLicenseSKU(size) {
    return (<div>
      {
        this.state.licenseTerm != null
          ? '1 x LIC-MI-' + size + '-' + this.state.licenseTerm + 'YR'
          : '1 x LIC-MI-' + size + '-xYR (x = 1, 3, 5, 7, 10 years)'
      }
    </div>)
  }

  renderCALicenseSKU() {
    return (<div>
      {
        this.state.cloudArchiveLicenseTerm != null
          ? '1 x LIC-MV-CA' + this.state.cloudArchiveDuration + '-' + this.state.cloudArchiveLicenseTerm + 'Y'
          : null
      }
    </div>)
  }

  renderSecurityLicenseSKU() {
    return (<div>
      {
        this.state.licenseTerm != null
          ? '1 x LIC-' + this.state.license + '-' + this.state.licenseTerm + 'YR'
          : null
      }
    </div>)
  }

  renderSwitchModel(model) {
    return (<div className={model + ' switchPictureContainer'}>
      <div onClick={this.state.switchModel != model
        ? () => this.setState({ switchModel: model, clickedSwitchModel: null })
        : () => this.setState({ switchModel: null })} className={'switchModel' + model}>
        <h6 className={this.state.switchModel == model
          ? 'selectedSwitchHeader'
          : ''}>{model}</h6>
        {<img src={'/img/mi/' + model + '.svg'} alt={model} className='modelIconXSmall modelIcon' />}
      </div>

      <div className={'switchInfo'}>
        {
          this.state.switchModel == 'XSmall' && model == this.state.switchModel
            ? <div>
              <div className='switchInfoSelected'>
                {this.renderSwitchInfo('Size', 'XSmall')}
                {this.renderSwitchInfo('Throughput', 'Up to 100Mbps')}
                {this.renderSwitchInfo('Supported Models', 'Z3x')}
                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                <p>{this.renderLicenseSKU('XS')}</p>
              </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_mi.pdf">Datasheet</a>
                <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a>
              </div>
            </div>

            : this.state.switchModel == 'Small' && model == this.state.switchModel
              ? <div>
                <div className='switchInfoSelected'>
                  {this.renderSwitchInfo('Size', 'Small')}
                  {this.renderSwitchInfo('Throughput', 'Up to 450Mbps')}
                  {this.renderSwitchInfo('Supported Models', 'MX6x')}
                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                  <p>{this.renderLicenseSKU('S')}</p>
                </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_mi.pdf">Datasheet</a>
                  <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a>
                </div>
              </div>

              : this.state.switchModel == 'Medium' && model == this.state.switchModel
                ? <div>
                  <div className='switchInfoSelected'>
                    {this.renderSwitchInfo('Size', 'Medium')}
                    {this.renderSwitchInfo('Throughput', 'Up to 750Mbps')}
                    {this.renderSwitchInfo('Supported Models', 'MX84 / MX100')}
                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                    <p>{this.renderLicenseSKU('M')}</p>
                  </div>
                  <div className='datasheetContainer'>
                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_mi.pdf">Datasheet</a>
                    <p className='datasheetDivider'> | </p>
                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a>
                  </div>
                </div>

                : this.state.switchModel == 'Large' && model == this.state.switchModel
                  ? <div>
                    <div className='switchInfoSelected'>
                      {this.renderSwitchInfo('Size', 'Large')}
                      {this.renderSwitchInfo('Throughput', 'Up to 5Gbps')}
                      {this.renderSwitchInfo('Supported Models', 'MX250 / MX400 / MX600')}
                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                      <p>{this.renderLicenseSKU('L')}</p>
                    </div>
                    <div className='datasheetContainer'>
                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_mi.pdf">Datasheet</a>
                      <p className='datasheetDivider'> | </p>
                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a>
                    </div>
                  </div>

                  : this.state.switchModel == 'XLarge' && model == this.state.switchModel
                    ? <div>
                      <div className='switchInfoSelected'>
                        {this.renderSwitchInfo('Size', 'XLarge')}
                        {this.renderSwitchInfo('Throughput', 'Up to 10Gbps')}
                        {this.renderSwitchInfo('Supported Models', 'MX450')}
                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                        <p>{this.renderLicenseSKU('XL')}</p>
                      </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_mi.pdf">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MI/Intro/Meraki_Insight_Introduction">Installation</a>
                      </div>
                    </div>
                    : ('')
        }
      </div>
    </div >)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ PICKING LOGIC @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  devicePickingLogic() {
    return (<div>
      {
        (<div>
          {
            devices.map(
              deviceModel => this.state.miSize != null && deviceModel.size != this.state.miSize
                ? ''
                : this.renderSwitchModel(deviceModel.model))
          }
        </div>)
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ BUBBLES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  optionBubbleLogic() {
    return (<div>
      {
        this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ licenseTerm: null })}>{'License Term: ' + this.state.licenseTerm + ' YR'}</div>
          : ''
      }
      {
        this.state.mxModel != null
          ? <div className='optionBubble' onClick={() => this.setState({ mxModel: null, miSize: null })}>{this.state.mxModel}</div>
          : ''
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ OPTIONS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })} className={this.state.licenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      {years}
    </div>)
  }

  renderOptionMXModels(model, size) {
    return (<div onClick={(
    ) => this.state.mxModel != model
        ? this.setState({ mxModel: model, miSize: size })
        : this.setState({ mxModel: null, miSize: null })} className={this.state.mxModel == model
          ? 'optionClicked'
          : 'option'}>
      {model}
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ RENDER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  render() {
    return (<div>

      <div className="form">

        <div className='leftForm'>
          {this.optionBubbleLogic()}
          {this.devicePickingLogic()}
        </div>

        <div className='rightFormContainer'>
          <div className='rightForm'>
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki Networking</h6>
              <h1>MI Meraki Insight</h1>
              <h3 className='bottomSubHeader'>
                MI is designed to give an easy way to monitor the performance of Web Applications and WAN Links and easily identify any network or application issues.
              </h3>
            </div>

            <div>
              <h2>Choose your license duration in years.</h2>
              <div className="secondaryOptionsContainer2">
                {this.renderSecondaryLicenseOptions(1)}
                {this.renderSecondaryLicenseOptions(3)}
                {this.renderSecondaryLicenseOptions(5)}
                {this.renderSecondaryLicenseOptions(7)}
                {this.renderSecondaryLicenseOptions(10)}
              </div>
            </div>

            <div>
              <h2>Choose your MX model.</h2>
              {this.renderOptionMXModels('Z3, Z3C', 'xs')}
              {this.renderOptionMXModels('MX6x', 's')}
              {this.renderOptionMXModels('MX75 / MX84 / MX85 / MX95 / MX100 / MX105', 'm')}
              {this.renderOptionMXModels('MX250 / MX400 / MX600', 'l')}
              {this.renderOptionMXModels('MX450', 'xl')}
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

// ========================================

export default MIPicker;
