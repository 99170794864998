import React, { useState, useEffect } from "react";
import createPersistedState from 'use-persisted-state';
import Toggle from "react-toggle";

const persistedDarkSetting = createPersistedState('isDark');

const DarkToggle = () => {
  const [isDark, setIsDark] = persistedDarkSetting();
  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDark]);

  return (
    <Toggle
      className="DarkToggle"
      checked={isDark}
      onChange={(event) => setIsDark(event.target.checked)}
      icons={{ checked: "", unchecked: "" }}
    />
  );
};

export default DarkToggle;
