import React from "react";
import { NavLink } from "react-router-dom";
import Toggle from "react-toggle";
import DarkToggle from './DarkToggle';

const Navigation = () => {
  return (
    <div className="navBar">
      <NavLink to="/" className="navLinks">
        <img src="/img/colorlogo.png" alt="merakilogo" className="merakilogo" />
      </NavLink>

      <div className="navLinksContainer">
        <NavLink to="/mr" className="navLinks" activeClassName="active">
          MR
        </NavLink>

        <NavLink to="/ms" className="navLinks" activeClassName="active">
          MS
        </NavLink>

        <NavLink to="/mx" className="navLinks" activeClassName="active">
          MX
        </NavLink>

        {/* <NavLink to="/mi" className="navLinks" activeClassName="active">
          MI
        </NavLink> */}

        <NavLink to="/mg" className="navLinks" activeClassName="active">
          MG
        </NavLink>

        <NavLink to="/mv" className="navLinks" activeClassName="active">
          MV
        </NavLink>

        <NavLink to="/mt" className="navLinks" activeClassName="active">
          MT
        </NavLink>

        <DarkToggle />

      </div>
    </div>
  );
};

export default Navigation;
